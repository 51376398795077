import React from "react";
import { Container, Button } from "reactstrap";
import no_access from "../../assets/image/no_access.svg";
const NoAccess = () => {
  return (
    <>
      <div className="noaccessfound">
        <Container fluid>
          <div className="noaccess_div noresult_div">
            <img src={no_access} />
            <h3>No Access Found</h3>
            <p className="mt-2">
              Upgrade your plan first to unlock all the features and enjoy full
              access.
            </p>
            {/* <Button className="btn btn-primary">Upgrade Now</Button> */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default NoAccess;
