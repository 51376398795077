import React, { useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import classnames from "classnames";
import { getData, postData } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PopupModel from "../PopupModel";

const LifeStyle = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [allNotifications, setAllQuestion] = useState([]);
  const [token, setToken] = useState(null);
  const [QuestionGroup, setQuestionGroup] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [next, setNext] = useState(true);
  const [pre, setPrevious] = useState(false);
  const [showsubmit, setShowsubmit] = useState(false);
  const [requiredFields, setRequiredFileds] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [DobDate, setDobDate] = useState(0);
  const [DobMonth, setDobMonth] = useState(0);
  const [DobYear, setDobYear] = useState(0);
  const [pregnancyDate, setpregnacyDate] = useState(0);
  const [pregnancyMonth, setpregnacyMonth] = useState(0);
  const [pregnancyYear, setpregnacyYear] = useState(0);
  const [validate, setValidation] = useState(true);
  const [ValidationData, setValidationData] = useState([]);
  const [selectedDueDate, setSelectedDueDate] = useState();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const myToggle = () => {
    setModal(!modal);
    navigate("/DietitianPrep");
  };

  const toggleNext = () => {
    setValidation(true);
    let validatedData = true;
    ValidationData[activeTab].map((id) => {
      const element = document.getElementsByName(id)[0];

      const elementValue = element ? element.value.trim() : "";
      console.log(elementValue);
      if (elementValue === "") {
        console.log("setfaklse");
        validatedData = false;
        setValidation(false);
      }
    });

    if (validatedData) {
      setActiveTab(activeTab + 1);
      var navTabs = document.querySelector(".nav-tabs");
      if (navTabs) {
        navTabs.scrollLeft += 200;
      }
    } else {
      toast.error("Please fill all Required Fields");
    }
  };

  const togglePre = () => {
    setActiveTab(activeTab - 1);
    var navTabs = document.querySelector(".nav-tabs");
    if (navTabs) {
      navTabs.scrollLeft -= 200;
    }
  };
  const handleDateChange = (date) => {
    console.log(date);
    setSelectedDate(date);
  };

  const handleDueDateChange = (date) => {
    console.log(date);
    setSelectedDueDate(date);
  };

  useEffect(() => {
    const fetchDeails = async () => {
      try {
        const res = await getData(
          "fetchTabDetails?sectionId=65ea037d5339eb2d23295437"
        );
        setValidationData(res.data.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchDeails();
  }, []);

  useEffect(() => {
    const numberOfKeys = Object.keys(QuestionGroup).length;

    if (activeTab >= 0) {
      setNext(true);
      // setShowsubmit(false);
    }

    if (activeTab == numberOfKeys - 1) {
      setNext(false);
      setShowsubmit(true);
    }

    if (activeTab == 0) {
      setPrevious(false);
      // setShowsubmit(false);
    }

    if (activeTab == numberOfKeys - 1 || activeTab > 0) {
      setPrevious(true);
      // setShowsubmit(false);
    }

    if (activeTab < numberOfKeys - 1) {
      // setPrevious(true);
      setShowsubmit(false);
    }
  }, [activeTab]);

  const onCheckboxChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (!checkboxes[name]?.includes(value)) {
      setCheckboxes({
        ...checkboxes,
        [name]: [...(checkboxes[name] || []), value],
      });
    } else {
      // remove from list
      setCheckboxes({
        ...checkboxes,
        [name]: [...(checkboxes[name] || [])].filter((i) => i !== value),
      });
    }
  };

  const onselectDobDate = (e) => {
    setDobDate(e.target.value);
  };
  const onselectDobMonth = (e) => {
    setDobMonth(e.target.value);
  };

  const onselectDobYear = (e) => {
    setDobYear(e.target.value);
  };

  ///////////pregnacy date

  const onselectpregnacyDate = (e) => {
    setpregnacyDate(e.target.value);
  };
  const onselectpregnacyMonth = (e) => {
    setpregnacyMonth(e.target.value);
  };

  const onselectpregnacyYear = (e) => {
    setpregnacyYear(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData(
          "fecthQuestion?sectionId=65ea037d5339eb2d23295437"
        );
        console.log(res.data.data);
        const requiredIds = res.data.data
          .filter((question) => question.isRequired)
          .map((question) => question._id);
        setRequiredFileds(requiredIds);
        setAllQuestion(res.data.data);
        const storedData =
          localStorage.getItem("userData") ||
          sessionStorage.getItem("userData");
        const storedDataparse = JSON.parse(storedData);
        console.log(token);
        // Access the token property
        setToken(storedDataparse.token);

        const questionGroups = {};

        res.data.data.forEach((question) => {
          const typeId = question.questionTypeId;

          if (!questionGroups[typeId]) {
            questionGroups[typeId] = [];
          }

          questionGroups[typeId].push(question);
        });

        setQuestionGroup(questionGroups);
        console.log(questionGroups);
      } catch (error) {
        console.log(error);
      }
    };
    // Call the fetch token function
    fetchData();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    const form = document.getElementById("questions_form");
    const data = Object.fromEntries(new FormData(form).entries());

    const finalObj = {
      ...data,
      ...checkboxes,

      // pregnancy: `${pregnancyYear}-${pregnancyMonth}-${pregnancyDate}`,
    };

    const dataArray = Object.keys(finalObj).map((key) => {
      return {
        questionId: key,
        answer: finalObj[key],
      };
    });

    const missingOrEmptyResponses = requiredFields.filter((requiredId) => {
      // Check if there is no response with the required questionId or the response is an empty string
      return !dataArray.some(
        (response) =>
          response.questionId === requiredId && response.answer !== ""
      );
    });

    console.log(dataArray, "--------final submitted data--------");
    // console.log(requiredFields);
    // console.log(missingOrEmptyResponses);
    if (missingOrEmptyResponses.length > 0) {
      toast.error("Please fill all the required questions.");
    } else {
      await postData(
        {
          userAnswer: dataArray,
          sectionId: "65ea037d5339eb2d23295437",
        },
        "insertUserAnswer",
        token
      )
        .then((res) => {
          if (res.data.status) {
            setModal(true);
            // toast.success("Questions submitted successfully");
            // setTimeout(() => {
            //   navigate("/DietitianPrep");
            // }, 2000);
          } else {
            toast.error("somthing went wrong");
          }
        })
        .catch((err) => {
          toast(err);
          //navigate("/Signup");
        });
    }
  };
  // Generate days
  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  // Generate months
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Generate years from 1960 to the current year
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1960 + 1 },
    (_, i) => 1960 + i
  );

  // Initialize the select boxes

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="12">
            <div className="border-bottom pb-4">
              <h1>Questionnaires</h1>
              <p>
                Family-Friendly Recipes: Directions to prepare delicious meals
                that are kid-friendly and can involve the entire family in the
                cooking process.
              </p>
            </div>
          </Col>
        </Row>
        <div class="breadcum mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link>My Library</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/Questionnaire">Questionnaire</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Family LifeStyle</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      <form onSubmit={onSubmit} id="questions_form" className="questionaretabs">
        <div>
          <Nav tabs>
            {[...new Set(allNotifications.map((n) => n.questionType))].map(
              (uniqueQuestionTypeId, index) => (
                <NavItem
                  className={classnames({
                    active: activeTab >= index,
                  })}
                  key={index}
                >
                  <NavLink>{uniqueQuestionTypeId}</NavLink>
                </NavItem>
              )
            )}
          </Nav>

          <TabContent className="mb-3 mt-3" activeTab={activeTab}>
            {Object.keys(QuestionGroup).map((typeId, index) => (
              <TabPane key={index} tabId={index}>
                <Row>
                  <Col sm="12">
                    {QuestionGroup[typeId].map((question, questionIndex) => (
                      <div className="mb-3 selectquestion" key={questionIndex}>
                        <h4 className="questionheading">
                          {question.question}{" "}
                          {question.isRequired && (
                            <sup style={{ color: "red" }}>*</sup>
                          )}
                        </h4>

                        {question.inputType === "select" && (
                          <select className="form-control" name={question._id}>
                            <option value="">Select Option</option>
                            {question.answer.map((answer, answerIndex) => (
                              <option value={answer} key={answerIndex}>
                                {answer}
                              </option>
                            ))}
                          </select>
                        )}

                        {question.inputType === "text" && (
                          <textarea
                            className="form-control"
                            rows="4"
                            name={question._id}
                            placeholder={
                              question.placeholder ? question.placeholder : ""
                            }
                          ></textarea>
                        )}

                        {question.inputType === "table" && (
                          <>
                            <p>
                              The purpose of this table is to inform of
                              medication/prescriptions consumed by the patient
                              CURRENTLY before the consultation
                            </p>
                            <div className="table-responsive">
                              <table className="table tablescustom  tablecustom2">
                                <thead>
                                  <tr>
                                    <th>Product Name</th>
                                    <th>Description</th>
                                    <th>Purpose</th>
                                    <th>Reason You Use It</th>
                                    <th>Dose</th>
                                    <th>Timing of Use</th>
                                    <th>Using Since</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <textarea placeholder="eg Lyrica Oral."></textarea>
                                    </td>
                                    <td>
                                      <textarea placeholder="Anticonvulsant."></textarea>
                                    </td>
                                    <td>
                                      <textarea placeholder="for Nerve Pain."></textarea>
                                    </td>
                                    <td>
                                      <textarea placeholder="My doctor recommended it a year ago."></textarea>
                                    </td>
                                    <td>
                                      <textarea placeholder="Two 25mg capsules per day."></textarea>
                                    </td>
                                    <td>
                                      <textarea placeholder="at breakfast and dinner"></textarea>
                                    </td>
                                    <td>
                                      <textarea placeholder="fast 18 months."></textarea>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                    <td>
                                      <textarea></textarea>
                                    </td>
                                  </tr>
                                </tbody>
                                {/* Add more rows as needed */}
                              </table>
                            </div>
                          </>
                        )}

                        {question.inputType === "input" && (
                          <input
                            className="form-control"
                            type="text"
                            name={question._id}
                            placeholder={
                              question.placeholder ? question.placeholder : ""
                            }
                          />
                        )}

                        {question.inputType === "dob" && (
                          <>
                            <div class="d-flex gap-2">
                              <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                value={selectedDate}
                                name={question._id}
                                className="form-control w-100"
                                dateFormat="MM/dd/yyyy"
                                maxDate={new Date()} // Disables future dates
                                showYearDropdown
                                dropdownMode="select" // Can be "scroll" or "select"
                              />
                            </div>
                          </>
                        )}

                        {question.inputType === "duedob" && (
                          <>
                            <div className="d-flex gap-2">
                              <DatePicker
                                selected={selectedDueDate}
                                onChange={handleDueDateChange}
                                value={selectedDueDate}
                                name={question._id}
                                className="form-control w-100"
                                dateFormat="MM/dd/yyyy"
                                minDate={new Date()} // Ensures past dates cannot be selected
                                showYearDropdown
                                dropdownMode="select" // Allows users to select a year from a dropdown
                              />
                            </div>
                          </>
                        )}

                        {/* {question.inputType === "duedob" && (
                          <>
                            <div class="d-flex gap-2">
                              <select
                                id="day"
                                name={question._id}
                                onChange={(e) => {
                                  onselectpregnacyDate(e);
                                }}
                              >
                                {days.map((day) => (
                                  <option key={day} value={day}>
                                    {day}
                                  </option>
                                ))}
                              </select>

                              <select
                                id="month"
                                name={question._id}
                                onChange={(e) => {
                                  onselectpregnacyMonth(e);
                                }}
                              >
                                {months.map((month, index) => (
                                  <option key={month} value={index + 1}>
                                    {month}
                                  </option>
                                ))}
                              </select>

                              <select
                                id="year"
                                name={question._id}
                                onChange={(e) => {
                                  onselectpregnacyYear(e);
                                }}
                              >
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </>
                        )} */}

                        {question.inputType === "checkbox" && (
                          <>
                            {question.answer.map((protein, proteinIndex) => (
                              <React.Fragment key={proteinIndex}>
                                <div className="questionform_check form-check">
                                  <Input
                                    checked={(
                                      checkboxes[question._id] || []
                                    ).includes(protein)}
                                    onChange={(e) => {
                                      onCheckboxChange(e);
                                    }}
                                    type="checkbox"
                                    value={protein}
                                    name={question._id}
                                  />
                                  <Label className="form-check-label">
                                    {protein}
                                  </Label>
                                </div>
                              </React.Fragment>
                            ))}
                          </>
                        )}

                        {question.inputType === "radio" && (
                          <>
                            {question.answer.map((protein, proteinIndex) => (
                              <React.Fragment key={proteinIndex}>
                                <div className="questionform_check form-check">
                                  <Input
                                    checked={(
                                      checkboxes[question._id] || []
                                    ).includes(protein)}
                                    onChange={(e) => {
                                      onCheckboxChange(e);
                                    }}
                                    type="radio"
                                    value={protein}
                                    name={question._id}
                                  />
                                  <Label className="form-check-label">
                                    {protein}
                                  </Label>
                                </div>
                              </React.Fragment>
                            ))}
                          </>
                        )}
                      </div>
                    ))}
                  </Col>
                </Row>
              </TabPane>
            ))}
          </TabContent>
        </div>
      </form>
      <ToastContainer />
      {pre && (
        <button
          className="cancel_btn btn btn-secondary me-2 mb-2"
          onClick={togglePre}
        >
          Previous
        </button>
      )}
      {next && (
        <button className="login_btn btn btn-primary mb-2" onClick={toggleNext}>
          Next
        </button>
      )}

      {showsubmit && (
        <button
          className="mb-2 login_btn btn btn-primary"
          type="submit"
          onClick={onSubmit}
        >
          Submit
        </button>
      )}
      <PopupModel
        modal={modal}
        toggle={myToggle}
        message="LifeStyle submit successfully."
        title="LifeStyle"
      />
    </>
  );
};

export default LifeStyle;
