import React from "react";
import {
  Row,
  Col,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Breadcrumb,
  BreadcrumbItem,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import check from "../../assets/image/success.svg";
const TermsConditions = () => {
  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <h1>Terms & Conditions</h1>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisici elit</p> */}
          </Col>
          <div class="breadcum mt-3">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link>Live Support</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Terms & Conditions</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Row>
      </div>
      <div className="card_box">
        <div className="termsuser">
          <h2>Privacy statement</h2>
          <p>
            WelFore Health LLC The Assembly Building | 400 Granby St. #115 |
            <br></br>
            Norfolk, VA 23510 <br></br>
            Hello@WelForeHealth.com
          </p>
        </div>
        <div className="termsuser mt-4">
          <h2>How we collect your information</h2>
          <p>
            The online platform of WelFore Health or its employees (dietitians,
            healthcare consultants) and volunteers collect data through
            submission forms, emails, live support chats. We only collect
            information that is absolutely necessary to provide assistance to
            our users.
          </p>
        </div>
        <div className="termsuser mt-4">
          <h2>What we do not do with user’s information</h2>
          <p>
            Information regarding your health status, medical conditions,
            financial transactions, healthcare advice, lifestyle recommendation,
            subscription to services provided by WelFore Health, in writing,
            through chats, or other electronic forms are held in strictest
            confidence.
          </p>
          <p>
            WelFore Health does not sell, exchange, give out, rent, swap, lend
            or disseminate any information about clients that are regarded as
            patient confidential, restricted by law, or specifically restricted
            by the client.
          </p>
        </div>
        <div className="termsuser mt-4">
          <h2>How do we use user’s information</h2>
          <p>
            All the provided information is used reasonably to process your
            applications and provide you counseling/advice regarding health or
            lifestyle, use of lifestyle interventions, foods, diet, healthcare
            devices. The information is only used when deemed necessary to
            provide personalized health advice or services. Your information may
            also be used when you ask for assistance.
          </p>
          <p>
            If you provide us information with the intent of fraud or crime,
            then your personal information might be shared with police,
            investigators, courts, attorneys, legal professionals, as permitted
            by law. It may also be shared with authorities when investigating
            negligence or un-willful acts.
          </p>
        </div>
        <div className="termsuser mt-4">
          <h2>Information not collected by us</h2>
          <p>
            We do not collect personal information in other ways except the use
            of a hit counter that simply records the number of visits.
            Information may be collected using cookies only if a client provides
            consent. However, the website may participate in affiliate programs
            and thus may pass basic traffic data. If you do not want other
            websites to know that you visited our website, kindly do not click
            on outside affiliate links on our website.
          </p>
        </div>
        <div className="termsuser mt-4">
          <h2>Limited right to use specific information</h2>
          <p>
            Any non-identifying images, stories, biographies shared with WelFore
            become the company’s property. Thus, WelFore reserves the right to
            use non-identifying information provided by clients for promotional
            purposes, fundraising, and other causes directly related to
            WelFore’s mission. <br></br>Further, clients will not be compensated
            in any manner when WelFore uses non-identifying or identifying
            information (contact information, phone number, names, photos) if
            such information is used without the client’s advance permission.
            <br></br>
            However, a client may specifically request us NOT to use any
            information provided by the client for promotional purposes.
            However, any such request must be provided in writing. We highly
            respect the privacy of clients, and we assure that we will never use
            clients’ photos, names, other personal information without getting
            direct or indirect consent from the client.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
