import React, { useEffect, useState } from "react";
import bell from "../../assets/image/bell.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Label, Input } from "reactstrap";
import { getData, postData } from "../../api/api";
const QuestionAnswer = () => {
  const [allNotifications, setAllQuestion] = useState([]);
  const [checkboxes, setCheckboxes] = useState({});
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData("fecthQuestion");

        setAllQuestion(res.data.data);
        const storedData =
          localStorage.getItem("userData") ||
          sessionStorage.getItem("userData");
        const storedDataparse = JSON.parse(storedData);

        // Access the token property
        setToken(storedDataparse.token);
        //   console.log(transformedData);
      } catch (error) {
        console.log(error);
      }
    };
    // Call the fetch token function
    fetchData();
  }, []);

  const onCheckboxChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (!checkboxes[name]?.includes(value)) {
      setCheckboxes({
        ...checkboxes,
        [name]: [...(checkboxes[name] || []), value],
      });
    } else {
      // remove from list
      setCheckboxes({
        ...checkboxes,
        [name]: [...(checkboxes[name] || [])].filter((i) => i !== value),
      });
    }
  };
  const NotificationItem = ({ notification }) => {
    const proteins = notification.answer.map((answer) => answer.split(","));
    return (
      <>
        <div key={notification.id} className="form-group mb-3">
          <label className="form-label">{notification.question}</label>
          {notification.inputType === "select" && (
            <select className="form-control" name={notification._id}>
              {proteins.map((protein, index) => (
                <option value={protein} key={index}>
                  {protein}
                </option>
              ))}
            </select>
          )}
          {notification.inputType === "text" && (
            <input
              className="form-control"
              type="text"
              name={notification._id}
            />
          )}

          {notification.inputType === "textarea" && (
            <textarea
              className="form-control"
              rows="4"
              name={notification._id}
            ></textarea>
          )}

          {notification.inputType === "checkbox" && (
            <>
              {proteins.map((protein, index) => (
                <React.Fragment key={index}>
                  <div className="questionform_check form-check">
                    <Input
                      checked={
                        (checkboxes[notification._id] || [])?.filter(
                          (i) => i == protein
                        )?.length > 0
                      }
                      onChange={(e) => {
                        onCheckboxChange(e);
                      }}
                      type="checkbox"
                      value={protein}
                      name={notification._id}
                    />
                    <Label className="form-check-label">{protein}</Label>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      </>
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const form = document.getElementById("questions_form");
    const data = Object.fromEntries(new FormData(form).entries());

    const finalObj = {
      ...data,
      ...checkboxes,
    };

    const dataArray = Object.keys(finalObj).map((key) => {
      return {
        questionId: key,
        answer: finalObj[key],
      };
    });

    await postData(
      {
        userAnswer: dataArray,
      },
      "insertUserAnswer",
      token
    )
      .then((res) => {
        res.data.status
          ? toast.success(res.data.message)
          : toast.error("somthing went wrong");
      })
      .catch((err) => {
        toast(err);
        //navigate("/Signup");
      });
  };
  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <h1>Questionnaires</h1>
            <p>
              Your personalized nutrition begins with our understanding of you!
              Our questionnaires help us explore your world - from demographics
              to preferred foods, cooking styles, & flavors - ensuring meal
              plans that meet your every needs.
            </p>
          </Col>
        </Row>
        <div className="questionform_list mt-4">
          <form onSubmit={onSubmit} id="questions_form">
            {allNotifications.map((n) => (
              <NotificationItem key={n.id} notification={n} />
            ))}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default QuestionAnswer;
