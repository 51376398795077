import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import glass from "../../../assets/image/img/glass.svg";
import smile from "../../../assets/image/img/smile.svg";
import upload from "../../../assets/image/img/upload.svg";
import { useFormik } from "formik";
import { DiarySchema } from "../../../schema";
import { postData } from "../../../api/api";
import store from "../../../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorModal from "../../ErrorModal";

const AddDiaryEntry = (args) => {
  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = useState(6);
  const [sliderValue2, setSliderValue2] = useState(4);
  const [token, setToken] = useState(null);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [loderVis, setloderVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [allmessages, setAllmessages] = useState([]);
  const { date } = useParams();
  const myToggle = () => {
    setModal(!modal);
  };
  const handleSliderChange = (value) => {
    // Handle the slider value change here
    setSliderValue(value); // Update the component's state with the new value
  };
  const handleSliderChange2 = (value) => {
    // Handle the slider value change here
    setSliderValue2(value); // Update the component's state with the new value
  };
  const getSliderValuePosition = () => {
    const leftPercentage = sliderValue * 10; // Adjust the positioning as needed
    return {
      left: `${leftPercentage}%`,
    };
  };
  const getSliderValuePosition2 = () => {
    const leftPercentage = sliderValue2 * 10; // Adjust the positioning as needed
    return {
      left: `${leftPercentage}%`,
    };
  };

  var formattedDate = date;

  if (date === "today") {
    const currentDate = new Date();
    formattedDate = currentDate.toISOString().split("T")[0];
  }

  const initialValues = {
    waterIntake: "",
    fiberEaten: "",
    colorsEaten: "",
    date: formattedDate,
    file: "",
    Mindset: "",
    physicalmovement: "",
  };
  const {
    values,
    handleBlur,
    touched,
    handleChange,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: DiarySchema,
    onSubmit: async (values) => {
      var loginButtons = document.querySelectorAll(".login_btn"); // Selects all buttons with class 'login_btn'
      loginButtons.forEach(function (button) {
        button.disabled = true; // Disables each button
      });
      setButtonsVisible(false);
      setloderVisible(true);

      const formData = new FormData();

      formData.append("waterIntake", values.waterIntake); // Assuming you have a 'text' field
      // formData.append("bloodPressure", values.bloodPressure);
      // formData.append("weightInLbs", values.weightInLbs); // Assuming you have a 'text' field
      formData.append("fiberEaten", values.fiberEaten);
      formData.append("colorsEaten", values.colorsEaten);
      formData.append("Mindset", values.Mindset);
      formData.append("physicalmovement", values.physicalmovement);
      formData.append("file", values.file);
      formData.append("date", values.date);

      await postData(formData, "addTodayDiary", token, "formdata")
        .then((res) => {
          if (res.data.status) {
            setButtonsVisible(true);
            setloderVisible(false);
            toast.success("Diary added successfully !");
            setTimeout(() => {
              navigate("/NutritionPlanner");
            }, 2000);
          } else {
            toast.error("Opration failed");
            setButtonsVisible(true);
            setloderVisible(false);
          }
        })
        .catch((err) => {
          navigate("/AddDiaryEntry");
        });
    },
  });

  useEffect(() => {
    const storedData =
      localStorage.getItem("userData") || sessionStorage.getItem("userData");
    const parsedData = JSON.parse(storedData);
    if (parsedData.token) {
      setToken(parsedData.token);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8" className="breadcrumbtop">
            <Link to="/NutritionPlanner">
              <ChevronLeft />
            </Link>
            <div>
              <h1>Add Diary Entry</h1>
              <p>At least one meal is mandatory per day</p>
            </div>
          </Col>
        </Row>
        <Row class="breadcum">
          <div class="breadcum mt-3">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/NutritionPlanner"> My Daily Nutrition Journal</Link>
              </BreadcrumbItem>

              <BreadcrumbItem active>Add Diary Entry</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Row>
      </div>
      <div className="adddairyentry card_box">
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
            const missingKeys = Object.keys(errors).filter(
              (key) => errors[key].length > 0
            );
            if (missingKeys.length > 0) {
              setModal(true);
              console.log("Missing keys:", missingKeys); // Debugging: Log the missing keys
              setAllmessages(missingKeys);
              return;
            }
          }}
          encType="Multipart/form-data"
        >
          <Row className="mb-3">
            <Col lg="8">
              <FormGroup>
                <Label>
                  How much water or low sweet beverages did you drink today?
                </Label>
                <small>
                  8 glasses (8 ounces per glass) recommended per day
                </small>
                <Input
                  type="number"
                  name="waterIntake"
                  value={values.waterIntake}
                  onChange={handleChange}
                  placeholder="5 glasses"
                />
              </FormGroup>
            </Col>
            {errors.waterIntake && touched.waterIntake ? (
              <p className="from-error">{errors.waterIntake}</p>
            ) : null}
          </Row>
          <Row className="mb-3">
            <Col lg="8">
              <FormGroup>
                <Label>
                  How many colors of the rainbow have you eaten today?
                </Label>
                <small>Target min. of 5 colors</small>
                <Input
                  className="form-control"
                  name="colorsEaten"
                  type="select"
                  value={values.colorsEaten}
                  onChange={handleChange}
                >
                  <option>Select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">Greater than 10</option>
                </Input>
              </FormGroup>
            </Col>
            {errors.colorsEaten && touched.colorsEaten ? (
              <p className="from-error">{errors.colorsEaten}</p>
            ) : null}
          </Row>
          <Row className="mb-3">
            <Col lg="8">
              <FormGroup>
                <Label>How many grams of fiber have you eaten today?</Label>
                <small>Target min. of 25 grams</small>
                <Input
                  className="form-control"
                  name="fiberEaten"
                  type="select"
                  value={values.fiberEaten}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="4">Less then 5g</option>
                  <option value="5">5g</option>
                  <option value="10">10g</option>
                  <option value="15">15g</option>
                  <option value="20">20g</option>
                  <option value="25">25g</option>
                  <option value="30">30g</option>
                  <option value="31">Greater than 30</option>
                </Input>
              </FormGroup>
            </Col>
            {errors.fiberEaten && touched.fiberEaten ? (
              <p className="from-error">{errors.fiberEaten}</p>
            ) : null}
          </Row>
          {/* <Row className="mb-3">
            <Col lg="8">
              <FormGroup>
                <Label>
                  How much water or low sweet beverages did you drink today?
                </Label>
                <small>8 glasses recommended per day</small>
                <Input type="number"  name="waterIntake"/>
              </FormGroup>
            </Col>
          </Row> */}
          {/* <Row className="mb-3">
            <Col lg="8">
              <FormGroup>
                <Label>What’s your body weight?</Label>
                <small>Please enter your body weight in pounds (lbs)</small>
                <Input
                  className="form-control"
                  name="weightInLbs"
                  type="select"
                  value={values.weightInLbs}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="50">50 lbs</option>
                  <option value="75">75 lbs</option>
                  <option value="100">100 lbs</option>
                  <option value="125">125 lbs</option>
                  <option value="185">185 lbs</option>
                  <option value="200">200 lbs</option>
                </Input>
              </FormGroup>
            </Col>
            {errors.weightInLbs && touched.weightInLbs ? (
              <p className="from-error">{errors.weightInLbs}</p>
            ) : null}
          </Row>
          <Row className="mb-3">
            <Col lg="8">
              <FormGroup>
                <Label>What’s your blood pressure?</Label>
                <small>
                  Normal blood pressure: Systolic 110-145 / Diastolic 70-00
                </small>
                <Input
                  className="form-control"
                  name="bloodPressure"
                  type="select"
                  value={values.bloodPressure}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="60-100"> 60-100</option>
                  <option value="100-120">110-145</option>
                  <option value="120-160">140-165</option>
                </Input>
              </FormGroup>
            </Col>

            {errors.bloodPressure && touched.bloodPressure ? (
              <p className="from-error">{errors.bloodPressure}</p>
            ) : null}
          </Row> */}
          <Row className="mb-3">
            <Col lg="8">
              <FormGroup>
                <Label>
                  How many minutes of intentional physical movement have you
                  enjoyed today?
                </Label>
                <small>
                  (E.g. walked the mall, gardened, danced, cleaned your home or
                  garage or washed the car? Please enter total minutes of
                  activity today.)
                </small>
                <Input
                  className="form-control"
                  name="physicalmovement"
                  type="select"
                  value={values.physicalmovement}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="Less then 30"> Less then 30 minutes</option>
                  <option value="60 minutes">60 minutes</option>
                  <option value="More then 60 ">More then 60 minutes</option>
                </Input>
              </FormGroup>
            </Col>

            {errors.physicalmovement && touched.physicalmovement ? (
              <p className="from-error">{errors.physicalmovement}</p>
            ) : null}
          </Row>
          <Row className="mb-3">
            <Col lg="8">
              <FormGroup>
                <Label>How do you feel today?</Label>
                <small>
                  {" "}
                  (select from a scale of 1 to 5, with 5 being that you feel
                  great today ?)
                </small>
                <Input
                  className="form-control"
                  name="Mindset"
                  type="select"
                  value={values.Mindset}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="1">1 I don’t feel so good</option>
                  <option value="2">2 I feel somewhat below average</option>
                  <option value="3">3 I feel okay</option>
                  <option value="4">4 I feel pretty good</option>
                  <option value="5">5 I feel great</option>
                </Input>
              </FormGroup>
            </Col>

            {errors.Mindset && touched.Mindset ? (
              <p className="from-error">{errors.Mindset}</p>
            ) : null}
          </Row>
          <Row className="mb-3 adddiaryfood">
            <Col lg="8">
              <FormGroup>
                <h2>Here’s my meal of the day 🙂</h2>
                <small>
                  Please Upload the picture of at least one FULL MEAL (include
                  your fruit, dessert, yogurt or other beverage, etc.)
                </small>

                {values.file ? (
                  <>
                    <div className="addfoodimg_box">
                      <img
                        style={{
                          width: "150px",
                          height: "150px",
                          position: "relative",
                        }}
                        src={URL.createObjectURL(values.file)}
                        alt="Preview"
                      />
                      <span
                        className="foodimgclose_btn"
                        onClick={(event) => {
                          setFieldValue("file", "");
                        }}
                      >
                        x
                      </span>
                      {/* <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        width: "20px",
                        height: "20px",
                        background: "#fff",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        border: "1px solid #ccc",
                      }}
                    >
                      &#10006;
                    </div> */}
                    </div>
                  </>
                ) : (
                  <label for="file" className="uploadphoto_btn">
                    Select Photo
                    <input
                      type="file"
                      name="file"
                      id="file"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                    />
                  </label>
                )}
              </FormGroup>
            </Col>

            {errors.file && touched.file ? (
              <p className="from-error">{errors.file}</p>
            ) : null}
          </Row>
          <Row className="mb-3 adddiaryfood">
            <Col lg="8">
              {/* <Button className="cancel_btn px-3 min-width-auto me-1">
                Back
              </Button> */}
              {buttonsVisible && (
                <Button
                  className="login_btn  px-3 min-width-auto ms-1"
                  color="primary"
                  type="submit"
                >
                  Submit to Upload
                </Button>
              )}

              {loderVis && <Spinner color="success">Loading...</Spinner>}
            </Col>
          </Row>
        </Form>
      </div>
      <ToastContainer />
      <ErrorModal
        modal={modal}
        toggle={myToggle}
        message={allmessages}
        title="Failed to add diary "
      />
    </>
  );
};

export default AddDiaryEntry;
