import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import axios from "axios";
// Import useNavigate or useNavigate based on your React Router version
import { Link, useNavigate } from "react-router-dom"; // for React Router v5
// import { useNavigate } from 'react-router-dom'; // for React Router v6

const NutritionBootcamp = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // for React Router v5
  // const navigate = useNavigate(); // for React Router v6

  useEffect(() => {
    axios
      .get(
        "https://api.xperiencify.io/api/public/coach/courses/?api_key=28be669bfcadfb9e08c1ca50b497e16897a513d6"
      )
      .then(function (response) {
        const filteredData = response.data.filter((course) =>
          [
            "HOW YOU GET NOURISHED - HOW YOUR FOOD GETS DIGESTED",
            "12-Week Nutrition Program – Back to Your Health Limited access",
          ].includes(course.title)
        );
        setData(filteredData);
      })
      .catch(function (error) {
        setData([]);
        console.log("An error occurred:", error);
      });
  }, []);

  // Function to handle click event

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <iframe
            src="https://app.xperiencify.io/"
            title="W3Schools Free Online Web Tutorials"
            height="1000px"
            width="500px"
          ></iframe>
          <Col lg="8">
            <h1>Nutrition Bootcamp</h1>
            <p>
              Celebrate your culinary achievement with each course! 'My
              Nutrition Academy' is your guide through 12 weeks of mastering and
              practicing healthy dietary principles.
            </p>
          </Col>
        </Row>
      </div>
      <Row>
        {data.map((course) => (
          <Col md={4}>
            <div className="bootcampmain_box">
              <Link
                to={"/course-detail"}
                state={{
                  course,
                }}
              >
                <div key={course.id}>
                  <img src={course.poster} alt={course.title} />
                  <h2>{course.title}</h2>
                  {/* Other elements */}
                </div>
              </Link>
            </div>
          </Col>
        ))}
        {/* <Col md={{ size: 10, offset: 1 }}>
          <div className="Bootcampmain_div"></div>
        </Col> */}
      </Row>
    </>
  );
};

export default NutritionBootcamp;
