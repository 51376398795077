import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPosts,
  loadMorePosts,
  toggleUpvote,
  toggleDownvote,
} from "../reducers/postReducer";
import { notify } from "../reducers/notificationReducer";
import PostCard from "./PostCard";
import SortTabBar from "./SortTabBar";
import LoadMoreButton from "./LoadMoreButton";
import LoadingSpinner from "./LoadingSpinner";
import getErrorMsg from "../utils/getErrorMsg";
import InfiniteScroll from "react-infinite-scroll-component";

import { Typography } from "@material-ui/core";
import { usePostListStyles } from "../styles/muiStyles";
import AddPostModal from "./PostFormModal";
import CreatePostModal from "./PostFormModal";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Pagination,
  Breadcrumb,
  BreadcrumbItem,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { gettoken } from "../services/posts";

const PostList = () => {
  const [sortBy, setSortBy] = useState("new");
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const posts = useSelector((state) => state.posts);
  const user = useSelector((state) => state.user);
  const [hasmore, sethasmore] = useState(true);
  const dispatch = useDispatch();
  const classes = usePostListStyles();
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      gettoken(token);
    }
  }, []);
  const handleTabChange = async (e, newValue) => {
    try {
      setPageLoading(true);
      await dispatch(fetchPosts("new"));
      setSortBy("new");
      setPageLoading(false);

      if (page !== 1) {
        setPage(1);
      }
    } catch (err) {
      setPageLoading(false);
      dispatch(notify(getErrorMsg(err), "error"));
    }
  };

  const handleLoadPosts = async () => {
    try {
      setLoadingMore(true);
      await dispatch(loadMorePosts(sortBy, page + 1));
      setPage((prevState) => prevState + 1);
      setLoadingMore(false);
      sethasmore(false);
    } catch (err) {
      setLoadingMore(false);
      sethasmore(false);

      dispatch(notify(getErrorMsg(err), "error"));
    }
  };

  useEffect(() => {
    handleTabChange();
  }, []);
  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <h1>My Community</h1>
            <p>
              Together, we grow stronger with every step. Join to experience
              unity and encouragement on your path to better health.
            </p>
          </Col>
        </Row>
      </div>
      <div className={classes.root}>
        {/* <SortTabBar
        sortBy={sortBy}
        handleTabChange={handleTabChange}
        subscribedTab={true}
        user={user}
      /> */}
        {/* <div className={classes.loadBtnWrapper}>
      </div> */}
        {/* <div style={{ width: "100%", height: 30, backgroundColor: "green" }}> */}
        <AddPostModal handleTabChange={handleTabChange} />
        {/* </div> */}
        {posts && posts.results && !pageLoading && (
          <InfiniteScroll
            dataLength={posts?.results?.length} //This is important field to render the next data
            next={handleLoadPosts}
            hasMore={hasmore}
            loader={<LoadingSpinner />}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            // below props only if you need pull down functionality
            // refreshFunction={this.refresh}
            // pullDownToRefresh
            // pullDownToRefreshThreshold={50}
            // pullDownToRefreshContent={
            //   <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
            // }
            // releaseToRefreshContent={
            //   <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
            // }
          >
            {posts?.results?.map((post) => (
              <PostCard
                post={post}
                key={post.id}
                textSubmission={post.textSubmission}
                image={
                  post?.imageSubmission?.imageLink
                    ? post?.imageSubmission?.imageLink
                    : null
                }
                toggleUpvote={toggleUpvote}
                toggleDownvote={toggleDownvote}
              />
            ))}
          </InfiniteScroll>
        )}

        {/* {posts && posts.results && !pageLoading ? (
        posts.results.map((post) => (
          <PostCard
            post={post}
            key={post.id}
            textSubmission={post.textSubmission}
            image={
              post?.imageSubmission?.imageLink
                ? post?.imageSubmission?.imageLink
                : null
            }
            toggleUpvote={toggleUpvote}
            toggleDownvote={toggleDownvote}
          />
        ))
      ) : (
        <LoadingSpinner text={"Fetching posts. Wait a sec."} />
      )}
      {sortBy === "subscribed" && posts?.results?.length === 0 && (
        <div className={classes.noSubscribedPosts}>
          <Typography variant="h5" color="secondary">
            No Posts Found
          </Typography>
          <Typography variant="h6" color="secondary">
            Subscribe to more subs if you haven't!
          </Typography>
        </div>
      )}
      {posts && "next" in posts && !pageLoading && (
        <LoadMoreButton
          handleLoadPosts={handleLoadPosts}
          loading={loadingMore}
        />
      )} */}
      </div>
    </>
  );
};

export default PostList;
