import React, { useState, useEffect } from "react";
import VideoPlayer from "../videoplayer/Video";
import {
  Row,
  Col,
  Pagination,
  Breadcrumb,
  BreadcrumbItem,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import PlayIcon from "../../assets/image/play-button.svg";
import { ChevronLeft, Clock } from "react-feather";
import videoimg1 from "../../assets/image/videoimg1.jpg";
import videoimg2 from "../../assets/image/videoimg2.jpg";
import videoimg3 from "../../assets/image/videoimg3.jpg";
import videoimg4 from "../../assets/image/videoimg4.jpg";
import videoimg5 from "../../assets/image/videoimg5.jpg";
import videoimg6 from "../../assets/image/videoimg6.jpg";
import videoimg7 from "../../assets/image/videoimg7.jpg";
import videoimg8 from "../../assets/image/videoimg8.jpg";
import videoimg9 from "../../assets/image/videoimg9.jpg";
import videoimg10 from "../../assets/image/videoimg10.jpg";
import videoimg11 from "../../assets/image/videoimg11.jpg";
import videoimg12 from "../../assets/image/videoimg12.jpg";
import playicon from "../../assets/image/videoplay_icon.svg";
import { getData } from "../../api/api";
const VideoDetails = (args) => {
  const [video, setVideoData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async (token) => {
      try {
        const res = await getData(`adminGetVideo?videoId=${id}`);
        console.log(res.data.data);
        setVideoData(res.data.data);
        //   console.log(transformedData);
      } catch (error) {
        console.log(error);
      }
    };

    // Call the fetch token function
    fetchData();
  }, [id]);

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="12">
            <div className="border-bottom pb-4 mealplanhead_div">
              <h1>
                <Link to="/Videos">
                  <ChevronLeft />
                </Link>
                Videos
              </h1>
              <p>
                Cooking Tips and Tricks: Practical advice for efficient cooking,
                ingredient substitutions, and kitchen hacks to save time and
                improve results
              </p>
            </div>
          </Col>
        </Row>
        <Row class="breadcum">
          <div class="breadcum mt-3">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link>My Library</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/Videos">Videos</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Video Details</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Row>
        <div className="card_box mt-5">
          <Row>
            <Col md="12" lg="12" sm="12" className="colp_10">
              <div className="videoview_div">
                <div className="videos_img position-relative">
                  {/* <img src={PlayIcon} className="videoplayicon" /> */}
                  {video.videoFile && (
                    <VideoPlayer
                      videoFile={`https://askportia.s3.amazonaws.com/${video.videoFile}`}
                      thumbnail={`https://askportia.s3.amazonaws.com/${video.thumbnailFile}`}
                    />
                  )}
                </div>
                <div className="videoshead_div">
                  <div className="text-end">
                    {/* <span className="videototal_time">
                        <Clock size={16} /> 3 mins
                      </span> */}
                  </div>
                  <h3>{`${video.videoName}`}</h3>
                  {/* <Link className="video_view_link">View</Link> */}
                </div>
                <p className="recipedtl_desc">{`${video.description}`}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default VideoDetails;
