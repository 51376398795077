import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

function PopupModel({ modal, toggle, message, title }) {
  const closeBtn = (
    <button
      className="close"
      type="button"
      onClick={toggle}
      style={{
        width: 35,
        height: 35,
        borderWidth: 0,
        background: "#b20000",
        borderRadius: "50%",
        position: "absolute",
        top: -16,
        right: -16,
        fontSize: 22,
        color: "#fff",
      }}
    >
      &times;
    </button>
  );

  return (
    <div>
      <Modal isOpen={modal} className={PropTypes.string}>
        <ModalHeader close={closeBtn} className="border-0 p-0"></ModalHeader>
        <ModalBody className="text-center" style={{ padding: 25 }}>
          <img
            src="https://cdn-icons-png.flaticon.com/512/5610/5610944.png"
            className="img-fluid"
            style={{ height: 69, width: 69, marginBottom: 14 }}
          />
          <h5 style={{ fontWeight: 700, marginBottom: 8, fontSize: 20 }}>
            {title}
          </h5>
          <p className="mb-0" style={{ fontSize: 16 }}>
            {message}
          </p>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default PopupModel;
