import React, { useEffect, useState } from "react";
import bell from "../../assets/image/bell.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col } from "reactstrap";
import { getData, postData } from "../../api/api";
// import timeago from "timeago.js";
import * as timeago from "timeago.js";
import not_found from "../../assets/image/not_found.svg";
import { LocalStorage } from "../Helper/NextSevenDates";
const Notifications = () => {
  let [notifications, setNotifications] = useState([]);
  const [token, setToken] = useState(null);
  const [totalCount, setTotalCount] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const fetchtoken = async () => {
      const storedData =
        (await localStorage.getItem("userData")) ||
        sessionStorage.getItem("userData");
      // Parse the JSON string back to an object
      const parsedData = JSON.parse(storedData);

      if (parsedData.token) {
        setToken(parsedData.token);
      }
    };
    fetchtoken();
  }, []);

  useEffect(() => {
    const storedData =
      localStorage.getItem("userData") || sessionStorage.getItem("userData");
    // Parse the JSON string back to an object
    const parsedData = JSON.parse(storedData);

    if (parsedData.token) {
      fetchData(parsedData.token);
    }
  }, [page]);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const fetchData = async (mytoken) => {
    try {
      // setToken(localStorage.getItem("token"))
      const res = await getData(`getUserNotification?page=${page}`, mytoken);
      console.log(res.data.data, "token12345", token);
      setToken(localStorage.getItem("token"));
      setNotifications(res.data.data);
      setTotalCount(res.data.totalCount);
      console.log("res.data.totalCount", res.data.totalCount);
    } catch (error) {
      console.log(error);
    }
  };
  const clearAllButton = async (e) => {
    e.preventDefault();
    // duumyFunc()
    await postData({}, "userClearNotification", token)
      .then((res) => {
        //console.log(res);
        if (res.data.status) {
          const storedData = LocalStorage.get("userData");

          const parsedData = JSON.parse(storedData);

          if (parsedData.token) {
            fetchData(parsedData.token);
          }
        } else {
          // toast(res.data.message);
        }
      })
      .catch((err) => {
        alert(`Opration Failed from server side ${err}`);
      });
  };
  //  notifications = [
  //   {
  //     id: 1,
  //     time: "2 min ago",
  //     title: "Meal Plan Update",
  //     subtitle:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam ex ea commodo consequat. ",
  //   },
  //   {
  //     id: 2,
  //     time: "5 hrs ago",
  //     title: "New Menu Options",
  //     subtitle:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. ",
  //   },
  //   {
  //     id: 3,
  //     time: "6 hrs ago",
  //     title: "Reminder: Meal Plan Review",
  //     subtitle:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  //   },
  //   {
  //     id: 4,
  //     time: "2 days ago",
  //     title: "Chat Notification",
  //     subtitle:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. ",
  //   },
  // ];
  const NotificationItem = ({ notification }) => {
    return (
      <div key={notification.id} className="notification-item">
        <div className="notification-icon">
          <img src={bell} className="img-fluid" />
        </div>
        <div className="notification-inner">
          <h6>{notification.title}</h6>
          <p>{notification.message}</p>
          <span className="notificationtime">
            {timeago.format(notification.createdAt)}
          </span>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="welcome_Div">
        <Row className="align-items-center">
          <Col lg="6">
            <h1>Notifications</h1>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col lg="12" className="d-flex justify-content-end">
            {notifications?.length > 0 && (
              <button
                onClick={clearAllButton}
                className="btn btn-primary notification-button"
              >
                Clear All
              </button>
            )}
          </Col>
        </Row>
        {/* <div className="notifications-list">
          {notifications?.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
            />
          ))}
        </div> */}
        {notifications?.length > 0 ? (
          <div className="notifications-list">
            {notifications.map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
              />
            ))}
          </div>
        ) : (
          <div className="noresult_div">
            <img src={not_found} />
            <h3>No Notification Found</h3>
          </div>
        )}
        {totalCount > 0 && (
          <div className="pagination mt-3">
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              ‹
            </button>
            {page > 3 && Math.ceil(totalCount / itemsPerPage) > 5 && (
              <button onClick={() => handlePageChange(1)}>1</button>
            )}
            {page > 4 && Math.ceil(totalCount / itemsPerPage) > 5 && (
              <span className="pagination-ellipsis">...</span>
            )}

            {Array.from({
              length: Math.min(5, Math.ceil(totalCount / itemsPerPage)),
            }).map((_, index) => {
              let startingIndex =
                page <= 3
                  ? 1
                  : page >= Math.ceil(totalCount / itemsPerPage) - 2
                  ? Math.ceil(totalCount / itemsPerPage) - 4
                  : page - 2;

              const pageNumber =
                startingIndex === 0 ? 1 + index : startingIndex + index;

              return (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  className={page === pageNumber ? "active" : ""}
                >
                  {pageNumber}
                </button>
              );
            })}
            {Math.ceil(totalCount / itemsPerPage) > 6 &&
              page < Math.ceil(totalCount / itemsPerPage) - 3 && (
                <span className="pagination-ellipsis">...</span>
              )}
            {page <= Math.ceil(totalCount / itemsPerPage) - 3 &&
              Math.ceil(totalCount / itemsPerPage) > 5 && (
                <button
                  onClick={() =>
                    handlePageChange(Math.ceil(totalCount / itemsPerPage))
                  }
                >
                  {Math.ceil(totalCount / itemsPerPage)}
                </button>
              )}

            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page === Math.ceil(totalCount / itemsPerPage)}
            >
              ›
            </button>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default Notifications;
