import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PropTypes from "prop-types";
import AddCardPayment from "../components/AddCardPayment";
import { getData, postData } from "../api/api";
import curve from "../assets/image/curve.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1920, min: 1024 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 1.5,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function AddCardModal({ modal, toggle, pid, token }) {
  const [cards, setCards] = useState([]);
  const closeBtn = (
    <button
      className="close"
      type="button"
      onClick={toggle}
      style={{
        width: 35,
        height: 35,
        borderWidth: 0,
        background: "#b20000",
        borderRadius: "50%",
        position: "absolute",
        top: -16,
        right: -16,
        fontSize: 22,
        color: "#fff",
      }}
    >
      &times;
    </button>
  );

  return (
    <div>
      <Modal
        id="addcardmodal"
        isOpen={modal}
        className={PropTypes.string}
        size="lg"
      >
        <ModalHeader close={closeBtn} className="border-0 p-0"></ModalHeader>
        <ModalBody style={{ padding: 25 }}>
          <AddCardPayment token={token} toggle={toggle}/>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default AddCardModal;
