import React, { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import logoimg from "../../assets/image/logo.png";
import icon1 from "../../assets/image/icon1.png";
import { getData } from "../../api/api";
import { ChevronLeft, Clock } from "react-feather";
const StaticMeal = (args) => {
  const { link, title, des } = useParams();
  const [csvData, setCsvData] = useState([]);
  const [csvDataModify, setCsvDataModified] = useState([]);

  return (
    <div className="mealplanpdf">
      <Row class="breadcum">
        <div class="breadcum mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/Mealplan">My Meal Plan</Link>
            </BreadcrumbItem>

            <BreadcrumbItem active>{`${title}`}</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Row>
      <div className="meal-description">{/* <p>{`${des}`}</p> */}</div>
      <div className="table-responsive">
        <img
          style={{ width: "100%" }}
          class="stat"
          src={`https://askportia.s3.amazonaws.com/${link}`}
        ></img>
      </div>
      <div className="mealplanpdffooter"></div>
    </div>
  );
};

export default StaticMeal;
