import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PropTypes from "prop-types";
import StripePayment from "../components/StripePayment";
import { getData, postData } from "../api/api";
import curve from "../assets/image/curve.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1920, min: 1024 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 1.5,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function PaymentModel({ modal, toggle, pid, token }) {
  const [cards, setCards] = useState([]);
  const [cardnumber, setCardId] = useState(null);

  const closeBtn = (
    <button
      className="close"
      type="button"
      onClick={toggle}
      style={{
        width: 35,
        height: 35,
        borderWidth: 0,
        background: "#b20000",
        borderRadius: "50%",
        position: "absolute",
        top: -16,
        right: -16,
        fontSize: 22,
        color: "#fff",
      }}
    >
      &times;
    </button>
  );

  const fetchData = async () => {
    try {
      // setToken(localStorage.getItem("token"))
      const res = await getData(`listCards`, token);
      console.log(res.data.data);
      setCards(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const payOld = async (cardid) => {
    setCardId(cardid);
    try {
      const res = await postData(
        {
          priceId: pid,
          payment_method: cardid,
        },
        "freetrial",
        token
      );
      if (res.data.status) {
        setCardId(null);
        toast(res.data.message);
        toggle();
        window.location.reload();
      } else {
        setCardId(null);
        toast("Validation Failed");
      }
    } catch (error) {
      setCardId(null);
      console.log(error);
      toast("An error occurred while saving the data.");
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  return (
    <div>
      <Modal isOpen={modal} className={PropTypes.string} size="lg">
        <ModalHeader close={closeBtn} className="border-0 p-0"></ModalHeader>
        <ModalBody style={{ padding: 25 }}>
          <>
            <Carousel
              responsive={responsive}
              showDots={false}
              arrows={false}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all 1s"
              transitionDuration={1000}
              containerClass="carousel-container"
            >
              {cards &&
                cards.map((card) => (
                  <div
                    key={card.cardID}
                    style={{
                      marginLeft: 7,
                      marginRight: 7,
                      marginBottom: "15px",
                    }}
                  >
                    <div className="creditcard">
                      <div className="cardname-bydefault d-flex align-items-center justify-content-between">
                        <h3 className="mb-0">{card.cardtype}</h3>
                        {card.default && <span>Default</span>}
                      </div>
                      <div className="cardno">
                        **** **** **** {card.cardNumber}
                      </div>
                      <div class="card-info">
                        <span>Expiry</span> {card.exp_month}/{card.exp_year}
                      </div>
                      <img src={curve} className="curveimg" />
                    </div>
                    <div className="text-center mt-3">
                      <span
                        className="btn btn-dark px-4 py-1 fs14"
                        onClick={() => payOld(card.cardID)}
                      >
                        {cardnumber == card.cardID ? "Paying..." : "Continue"}
                      </span>{" "}
                      :
                    </div>
                    {/* <p>
                    <strong>Card Type:</strong> {card.cardtype}
                  </p>
                  <p>
                    <strong>Card Number:</strong> **** **** ****{" "}
                    {card.cardNumber}
                  </p>
                  <p>
                    <strong>Expiry:</strong> {card.exp_month}/{card.exp_year}
                  </p> */}
                    {/* {card.default && (
                    <p>
                      <strong>Default Card</strong>
                    </p>
                  )} */}
                  </div>
                ))}
            </Carousel>
          </>

          <StripePayment pid={pid} token={token} toggle={toggle} />
        </ModalBody>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default PaymentModel;
