import React, { useState, useEffect } from "react";
import VideoPlayer from "../videoplayer/Video";
import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { ChevronLeft, Clock, FileText } from "react-feather";
import videoimg1 from "../../assets/image/videoimg1.jpg";
import videoimg2 from "../../assets/image/videoimg2.jpg";
import videoimg3 from "../../assets/image/videoimg3.jpg";
import videoimg4 from "../../assets/image/videoimg4.jpg";
import videoimg5 from "../../assets/image/videoimg5.jpg";
import videoimg6 from "../../assets/image/videoimg6.jpg";
import videoimg7 from "../../assets/image/videoimg7.jpg";
import videoimg8 from "../../assets/image/videoimg8.jpg";
import videoimg9 from "../../assets/image/videoimg9.jpg";
import videoimg10 from "../../assets/image/videoimg10.jpg";
import videoimg11 from "../../assets/image/videoimg11.jpg";
import videoimg12 from "../../assets/image/videoimg12.jpg";
import playicon from "../../assets/image/videoplay_icon.svg";
import { getData } from "../../api/api";
const CourseDetail = (props) => {
  const location = useLocation();

  const { course } = location.state;

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="12">
            <div className="border-bottom pb-4 mealplanhead_div">
              <h1>
                <Link to="/NutritionBootcamp">
                  <ChevronLeft />
                </Link>
                Nutrition Bootcamp
              </h1>
            </div>
          </Col>
        </Row>

        <Row class="breadcum">
          <div class="breadcum mt-3">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/NutritionBootcamp"> Nutrition Bootcamp </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Course Details</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Row>
        <div className="card_box mt-5">
          <Row>
            <Col md="12" lg="12" sm="12" className="colp_10">
              <div className="bootcampdtl_box">
                <h2 className="bootcamp_title">{course.title}</h2>
                <img src={course.poster} alt="" />
                {/* <h5 className="bootcamp_name">Recipe Description</h5> */}
                <div
                  className="bootcamp_description"
                  dangerouslySetInnerHTML={{ __html: course.description }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CourseDetail;
