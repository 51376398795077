import * as Yup from "yup";

export const signUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(/\S+/, "First Name cannot be empty or contain only whitespace")
    .required("Enter First Name"),
  lastName: Yup.string()
    .trim()
    .matches(/\S+/, "Last Name cannot be empty or contain only whitespace")
    .required("Enter Last Name"),
  email: Yup.string()
    .trim()
    .matches(/\S+/, "Email cannot be empty or contain only whitespace")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email address"
    )
    .required("Enter Email"),
  city: Yup.string().required("Select City"),
  state: Yup.string().required("Select State"),
  country: Yup.string().required("Select Country"),
  age: Yup.string().required("Enter Age."),
  zipCode: Yup.string().trim().required("Enter Zip Code"),
  password: Yup.string()
    .trim()
    .matches(/\S+/, "Password cannot be empty or contain only whitespace")
    .min(6, "Password must be at least 6 characters")
    .required("Enter Password"),
  ReEnterPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "Passwords must match to confirm password "
    )
    .required("Enter Confirm Password "),
});

export const ResetSchema = Yup.object().shape({
  Otp: Yup.string().required("Last Name is required"),
  Email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  NewPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  RepeatPassword: Yup.string()
    .oneOf(
      [Yup.ref("NewPassword"), null],
      "Passwords must match to confirm password"
    )
    .required("Re-enter Password is required"),
});

export const ChangePasswordSchema = Yup.object().shape({
  CurrentPassword: Yup.string().required("Current Password is required"),
  NewPassword: Yup.string()
    .min(6, "New Password must be at least 6 characters")
    .required("New Password  is required"),
  RepeatPassword: Yup.string()
    .oneOf(
      [Yup.ref("NewPassword"), null],
      "Passwords must match to confirm password"
    )
    .required("Re-enter Password is required"),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const SmartGoalSchema = Yup.object().shape({
  smartGoal: Yup.string().required("Please fill your smart goal."),
  track: Yup.string().required("This field is required."),
  Achievable: Yup.string().required("This field is required."),
  accomplishing: Yup.string().required("This field is required."),
  Bound: Yup.string().required("This field is required."),
});

export const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  mobileNumber: Yup.string().required("Phone number is required"),
  age: Yup.string().required("age is required"),
  zipCode: Yup.string().trim().required("Enter Zipcode"),
  password: Yup.string().trim(),
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  password: Yup.string().required("Password is required"),
});

const MAX_FILE_SIZE = 102400; //100KB

const validFileExtensions = {
  image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
};

function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
  );
}

export const DiarySchema = Yup.object().shape({
  waterIntake: Yup.string().required("Enter water glasses"),
  colorsEaten: Yup.string().required("Select color eaten"),
  fiberEaten: Yup.string().required("Select fiber eaten"),
  Mindset: Yup.string().required("Select  Mindset"),
  physicalmovement: Yup.string().required("Select  physical movement"),
  // fileimg: Yup.mixed()
  //   .required("Please uplode foods image you taked today")
  //   .test("is-valid-type", "Not a valid image type", (value) =>
  //     isValidFileType(value && value.name.toLowerCase(), "image")
  //   )
  //   .test(
  //     "is-valid-size",
  //     "Max allowed size is 100KB",
  //     (value) => value && value.size <= MAX_FILE_SIZE
  //   ),
});

//Questionnaire

export const AboutYouQuestionnaire = Yup.object().shape({
  First_Name: Yup.string().required("waterIntake is required"),
  Last_Name: Yup.string().required("colorsEaten is required"),
  activityLevel: Yup.string().required("fiberEaten is required"),
  Gender: Yup.string().required("weight is required"),
  enthusiastic: Yup.string().required("bloodPressure is required"),
  descriptionselect: Yup.string().required("bloodPressure is required"),

  // fileimg: Yup.mixed()
  //   .required("Please uplode foods image you taked today")
  //   .test("is-valid-type", "Not a valid image type", (value) =>
  //     isValidFileType(value && value.name.toLowerCase(), "image")
  //   )
  //   .test(
  //     "is-valid-size",
  //     "Max allowed size is 100KB",
  //     (value) => value && value.size <= MAX_FILE_SIZE
  //   ),
});

export const FavriotFoodQuestionnaire = Yup.object().shape({
  RedColoredFruits: Yup.string().required("Red Colored Fruits is required"),
  OrangeColoredFruits: Yup.string().required(
    "Orange Colored Fruits is required"
  ),
  YellowColoredFruits: Yup.string().required(
    "Yellow Colored Fruits is required"
  ),
  GreenColoredFruits: Yup.string().required("Green Colored Fruits is required"),
  ProteinFoods: Yup.string().required("Protein Foods is required"),
  RootVeggies: Yup.string().required("Root Veggies is required"),
  EthnicCuisines: Yup.string().required("Ethnic Cuisines is required"),

  // fileimg: Yup.mixed()
  //   .required("Please uplode foods image you taked today")
  //   .test("is-valid-type", "Not a valid image type", (value) =>
  //     isValidFileType(value && value.name.toLowerCase(), "image")
  //   )
  //   .test(
  //     "is-valid-size",
  //     "Max allowed size is 100KB",
  //     (value) => value && value.size <= MAX_FILE_SIZE
  //   ),
});
