import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import visacard from "../../assets/image/logos--visa.svg";
import mastercard from "../../assets/image/logos--mastercard.svg";
import { getData, postData } from "../../api/api";
import AddCardModal from "../AddCardModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Subscriptions = () => {
  const [subesdata, setscribeData] = useState([]);
  const [token, setToken] = useState(null);
  const [modal, setModal] = useState(false);
  const [cardnumber, setCardId] = useState(null);

  useEffect(() => {
    const storedData =
      localStorage.getItem("userData") || sessionStorage.getItem("userData");
    const parsedData = JSON.parse(storedData);
    if (parsedData.token) {
      setToken(parsedData.token);
    }
  }, []);

  const myToggle = () => {
    setModal(!modal);
  };

  const letSubscribe = async () => {
    setModal(true);
  };
  const fetchData = async () => {
    try {
      const res = await getData(`getSubscriptionDetail`, token);
      console.log("subs data", res.data.data);
      setscribeData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const removeCard = async (id) => {
    setCardId(id);
    try {
      const res = await postData(
        {
          payment_method: id,
        },
        "deleteCard",
        token
      );
      if (res.data.status) {
        toast(res.data.message);
        setCardId(null);
        fetchData();
      } else {
        toast("Validation failed.");
        setCardId(null);
      }
    } catch (error) {
      console.log(error);
      toast("An error occurred while saving the data.");
      setCardId(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  return (
    <>
      <div className="welcome_Div subsriptionouter ">
        <Row className="align-items-center">
          <Col lg="6">
            <h1>Subscriptions</h1>
          </Col>
        </Row>
        <div className="card_box mt-3 ">
          <div className="d-flex align-items-center justify-content-between gap-3">
            <div className="left_subs">
              <span className="iconsubscription">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M6 19h5v4l-2.5-1.5L6 23zM20 1h-8v22l2.5-1.5L17 23v-2h3a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2m-6 4h2v2h-2Zm0 5h2v2h-2Zm0 5h2v2h-2ZM4 1a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2V3h7V1Z"
                  />
                  <path
                    fill="currentColor"
                    d="M6 5h2v2H6zm0 5h2v2H6zm0 5h2v2H6z"
                  />
                </svg>
              </span>
              <div className="subs_content">
                <h2>
                  {subesdata && subesdata.productDetail
                    ? subesdata.productDetail.productName
                    : "N/A"}
                </h2>

                <span
                  className={
                    subesdata && subesdata.subscriptionDetail
                      ? subesdata.subscriptionDetail.subscriptionStatus ===
                        "active"
                        ? "badgeactive"
                        : "badgeinactive"
                      : "badgeinactive"
                  }
                >
                  {subesdata && subesdata.subscriptionDetail
                    ? subesdata.subscriptionDetail.subscriptionStatus ===
                      "active"
                      ? "Active"
                      : subesdata.subscriptionDetail.subscriptionStatus ===
                        "trialing"
                      ? "Free trial"
                      : subesdata.subscriptionDetail.subscriptionStatus ===
                        "canceled"
                      ? "Canceled"
                      : "N/A"
                    : "N/A"}
                </span>
              </div>
            </div>
            <Link
              to="/managebilling"
              className="btn btn-primary min-width-140 d-inline-flex align-items-center justify-content-center"
            >
              Upgrade Plan
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-3 subs-list mt-3">
            <div className="d-flex align-items-center gap-3">
              <span className="iconsubsouter">
                <span className="iconsubs" style={{ color: "#920000" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 12a9 9 0 1 0 18 0a9 9 0 1 0-18 0m2.7-6.3l12.6 12.6"
                    />
                  </svg>
                </span>
              </span>
              <h4>End Of Subscription</h4>
            </div>
            <h2>
              On{" "}
              {subesdata && subesdata.subscriptionDetail
                ? formatDate(subesdata.subscriptionDetail.subscriptionEndDate)
                : "N/A"}
            </h2>
          </div>
          <div className="d-flex align-items-center justify-content-between gap-3 subs-list mt-3">
            <div className="d-flex align-items-center gap-3">
              <span className="iconsubsouter">
                <span className="iconsubs" style={{ color: "#999999" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 6v3l4-4l-4-4v3c-4.42 0-8 3.58-8 8c0 1.57.46 3.03 1.24 4.26L6.7 14.8A5.9 5.9 0 0 1 6 12c0-3.31 2.69-6 6-6m6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8c0 3.31-2.69 6-6 6v-3l-4 4l4 4v-3c4.42 0 8-3.58 8-8c0-1.57-.46-3.03-1.24-4.26"
                    />
                  </svg>
                </span>
              </span>
              <h4>Automatic Renewal</h4>
            </div>
            <h2>
              On{" "}
              {subesdata && subesdata.subscriptionDetail
                ? formatDate(subesdata.subscriptionDetail.subscriptionEndDate)
                : "N/A"}
            </h2>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4">
          <h3 className="homemeal_head mb-0">Payment Methods</h3>
          <Link
            onClick={() => letSubscribe()}
            className="btn btn-primary px-4 d-inline-flex align-items-center justify-content-center"
          >
            Add
          </Link>
        </div>
        <div className="card_box mt-3 ">
          {subesdata &&
            subesdata?.card?.map((card, index) => (
              <div className="d-flex align-items-center justify-content-between gap-3">
                <div className="left_subs">
                  <span className="iconsubscription">
                    {card.cardtype == "visa" ? (
                      <img src={visacard} alt="Visa Card" />
                    ) : (
                      <img src={mastercard} alt="MasterCard" />
                    )}
                  </span>
                  <div className="subs_content">
                    <h2>**** **** **** {card.cardNumber}</h2>
                    <span>Valid until {card.exp_year}</span>
                  </div>
                </div>
                {cardnumber == card.cardID ? (
                  <p>Removing....</p>
                ) : (
                  <Link
                    className="closesubs"
                    onClick={() => removeCard(card.cardID)}
                  >
                    Remove
                  </Link>
                )}
              </div>
            ))}
        </div>
        <h3 className="homemeal_head mb-0 mt-4">Invoices</h3>
        <div className="table-responsive mt-1">
          <table class="table tablecustom2">
            <thead>
              <tr>
                <th># Invoce Number</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {" "}
                  {subesdata && subesdata.subscriptionDetail
                    ? subesdata.subscriptionDetail.invoice
                    : "N/A"}
                </td>
                <td>
                  {subesdata && subesdata.subscriptionDetail
                    ? formatDate(subesdata.subscriptionDetail.createdAt)
                    : "N/A"}
                </td>
                <td>
                  €
                  {subesdata && subesdata.subscriptionDetail
                    ? subesdata.subscriptionDetail.amount
                    : "N/A"}
                </td>
                <td>
                  <span className="badgeactive">Paid</span>
                </td>
                {/* <td>
                  <Link>Show</Link>
                </td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <AddCardModal modal={modal} toggle={myToggle} token={token} />
      <ToastContainer />
    </>
  );
};

export default Subscriptions;
