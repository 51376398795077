import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function FreeTrialMessageModal({ modal, toggle }) {
  const closeBtn = (
    <button
      className="close"
      type="button"
      onClick={toggle}
      style={{
        width: 35,
        height: 35,
        borderWidth: 0,
        background: "#b20000",
        borderRadius: "50%",
        position: "absolute",
        top: -16,
        right: -16,
        fontSize: 22,
        color: "#fff",
      }}
    >
      &times;
    </button>
  );

  return (
    <div>
      <Modal isOpen={modal} className={PropTypes.string} size="lg">
        <ModalHeader close={closeBtn} className="border-0 p-0"></ModalHeader>
        <ModalBody className="text-center messageModal_body">
          <h1>
            Congratulations on taking the first step toward a healthier, more
            balanced life! 🎉 We’re thrilled to have you here.{" "}
          </h1>
          <p>
            Over the next 14 days, you’ll get full access to all the
            personalized tools and resources AskWelFore offers, including
          </p>

          <p>
            🌿 Custom Nutrition Plans: Tailored to your unique heritage and
            health needs
          </p>

          <p>
            <strong>Step 1 -</strong> just fill out the questionnaire to tell us
            your food preferences: <Link to="/FoodPrefrence">click here</Link>
          </p>

          <ul>
            <li>
              📊 Real-Time Progress Tracking: Monitor your meals, activity, and
              progress in one place.
            </li>
            <li>
              👨‍🏫 Expert Guidance: Culturally relevant tips, meal suggestions,
              and wellness insights to help you reach your goals.
            </li>
            <li>
              💬 Community Support: Connect with like-minded people who are on
              the same journey as you.
            </li>
          </ul>
          {/* <p>
            <strong>Start Your Free 14-Day's Trial Now!</strong>
          </p> */}

          <p>
            Let’s make your health goals a reality—together! To get started,
            explore your dashboard, set your health goals, and begin tracking
            your progress. And don’t forget: after your trial ends, you can
            continue to enjoy everything the app offers with our affordable
            monthly subscription.
          </p>

          <p>
            If you need any assistance or have questions, we’re here to help
            every step of the way. Enjoy your journey to better health!
          </p>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default FreeTrialMessageModal;
