import React, { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import logoimg from "../../assets/image/logo.png";
import icon1 from "../../assets/image/icon1.png";
import { getData } from "../../api/api";
import { ChevronLeft, Clock } from "react-feather";
const Weightloss = (args) => {
  const { id } = useParams();
  const [csvData, setCsvData] = useState([]);
  const [csvDataModify, setCsvDataModified] = useState([]);

  useEffect(() => {
    const fetchData = async (token) => {
      try {
        const res = await getData(`SinglemealPlanForUser?mealId=${id}`);

        setCsvData(res.data.data);
        const weeklyMeal = res.data.data.weeklyMeal;

        const newFormat = weeklyMeal.reduce((acc, day) => {
          const { field1, ...meals } = day;
          acc.push({ [field1]: meals });
          return acc;
        }, []);

        transformData(newFormat);
      } catch (error) {
        console.log(error);
      }
    };

    // Call the fetch token function
    fetchData();
  }, [id]);

  // Function to transform the data
  function transformData(newFormat) {
    let result = {};

    // Initialize result object with empty arrays for each meal type
    newFormat.forEach((dayObj, index) => {
      const dayKey = `Day${index + 1}`;
      result[dayKey] = {
        BreakFast: [],
        "AM Snacks": [],
        Lunch: [],
        "Pm Snacks": [],
        Dinner: [],
        "Bed Snacks": [],
      };
    });

    // Populate the result object
    Object.keys(result).forEach((dayKey) => {
      newFormat.forEach((dayObj) => {
        const dayData = dayObj[Object.keys(dayObj)[0]]; // Get the day's meal data
        Object.keys(dayData).forEach((mealType) => {
          result[dayKey][mealType].push(dayData[mealType]); // Push the meal data
        });
      });
    });

    setCsvDataModified(result.Day1);
    console.log(result);
  }

  return (
    <div className="mealplanpdf">
      <Row class="breadcum">
        <div class="breadcum mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/Mealplan">My Meal Plan</Link>
            </BreadcrumbItem>

            <BreadcrumbItem active>Plan Details</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Row>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h2>{csvData ? csvData.mealplanName : ""}</h2>{" "}
        <Link to="#">
          {" "}
          <img
            style={{ height: 85 }}
            src={logoimg}
            className="img-fluid"
            alt=""
          />
        </Link>
      </div>
      <div className="table-responsive">
        <table className="table tablemealplan">
          <thead>
            <tr>
              <th width="12.5%"></th>
              <th width="12.5%" className="text-center">
                DAY1
              </th>
              <th width="12.5%" className="text-center">
                DAY2
              </th>
              <th width="12.5%" className="text-center">
                DAY3
              </th>
              <th width="12.5%" className="text-center">
                DAY4
              </th>
              <th width="12.5%" className="text-center">
                DAY5
              </th>
              <th width="12.5%" className="text-center">
                DAY6
              </th>
              <th width="12.5%" className="text-center">
                DAY7
              </th>
            </tr>
          </thead>

          <tbody>
            {Object.entries(csvDataModify).map(([key, values]) => (
              <tr key={key}>
                <th className="text-center">
                  {key}
                  {/* <span>
                    <img src={icon1} alt="" />
                  </span> */}
                </th>

                {values.map((item, i) => (
                  <td>
                    <p key={i}>{item}</p>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mealplanpdffooter">
        <span className="me-3 mb-2">
          <i>E-book Source:</i>
        </span>
        <span className="me-3  mb-2">
          <span
            className="mealplancircle"
            style={{ background: "#41773b" }}
          ></span>
          Veggie Ebook
        </span>
        <span className="me-3  mb-2">
          <span
            className="mealplancircle"
            style={{ background: "#0069a3" }}
          ></span>
          Diabetic Ebook
        </span>
        <span className="me-3  mb-2">
          <span
            className="mealplancircle"
            style={{ background: "#aa131c" }}
          ></span>
          Holiday Ebook
        </span>
      </div>
    </div>
  );
};

export default Weightloss;
