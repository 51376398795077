import React, { useState, useEffect } from "react";
import VideoPlayer from "../videoplayer/Video";
import {
  Row,
  Col,
  Pagination,
  Breadcrumb,
  BreadcrumbItem,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { getData } from "../../api/api";
import { Link } from "react-router-dom";
import PlayIcon from "../../assets/image/play-button.svg";
const Videos = (args) => {
  const [videos, setVideoData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 6; // Number of videos to display per page

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData("adminGetVideoList");
        console.log(res.data.data);
        setVideoData(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const filteredVideos = videos.filter((video) =>
    video.videoName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the indexes of the first and last videos for the current page
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = filteredVideos.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="12">
            <div className="border-bottom pb-4">
              <h1>Videos</h1>
              <p>
                Cooking Tips and Tricks: Practical advice for efficient cooking,
                ingredient substitutions, and kitchen hacks to save time and
                improve results
              </p>
            </div>
          </Col>
        </Row>
        <div class="breadcum mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link>My Library</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Videos</BreadcrumbItem>
          </Breadcrumb>
          <input
            type="text"
            className="form-control mb-4"
            placeholder="Search videos..."
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1); // Reset to first page when search changes
            }}
          />
        </div>
        <div className="card_box mt-3 videocardbox">
          <Row>
            {currentVideos.map((videoItem, index) => (
              <Col md="6" lg="4" xxl="3" sm="6" className="colp_10" key={index}>
                <Link
                  to={`/VideoDetails/${videoItem._id}`}
                  className="text-decoration-none"
                >
                  {/* <div className="videoview_div"> */}
                  <div className="videosmain_div">
                    <div className="videos_img">
                      {/* Use the VideoPlayer component */}
                      <img src={PlayIcon} className="videoplayicon" />
                      <VideoPlayer
                        videoFile={`https://askportia.s3.amazonaws.com/${videoItem.videoFile[0]}`}
                        thumbnail={`https://askportia.s3.amazonaws.com/${videoItem.thumbnailFile}`}
                      />
                    </div>
                    <div className="videoshead_div">
                      <div className="text-end">
                        {/* <span className="videototal_time">
                        <Clock size={16} /> 3 mins
                      </span> */}
                      </div>
                      <h3>{videoItem.videoName}</h3>
                    </div>
                    <p className="recipedtl_desc mb-0">
                      {videoItem.description.length > 20
                        ? `${videoItem.description.substring(0, 20)}...`
                        : videoItem.description}
                    </p>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
        <Pagination className="pagination_div" aria-label="Page navigation">
          <PaginationItem
            className={`nextprev_btn ${currentPage === 1 ? "disabled" : ""}`}
          >
            <PaginationLink
              onClick={() => paginate(currentPage - 1)}
              previous
            />
          </PaginationItem>
          {Array.from({
            length: Math.ceil(videos.length / videosPerPage),
          }).map((_, index) => (
            <PaginationItem key={index} active={index + 1 === currentPage}>
              <PaginationLink onClick={() => paginate(index + 1)}>
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem
            className={`nextprev_btn ${
              currentPage === Math.ceil(videos.length / videosPerPage)
                ? "disabled"
                : ""
            }`}
          >
            <PaginationLink onClick={() => paginate(currentPage + 1)} next />
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
};

export default Videos;
