import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PostForm from "./PostForm";
import HideOnScroll from "./HideOnScroll";
import { getCircularAvatar } from "../utils/cloudinaryTransform";

import { DialogTitle, DialogContent } from "./CustomDialogTitle";
import {
  Dialog,
  Button,
  Fab,
  IconButton,
  Paper,
  Avatar,
  useMediaQuery,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";
import { useDialogStyles } from "../styles/muiStyles";
import { useTheme } from "@material-ui/core/styles";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ImageIcon from "@material-ui/icons/Image";
import LinkIcon from "@material-ui/icons/Link";
import EditIcon from "@material-ui/icons/Edit";
import store from "../store";

const AddPostModal = ({
  actionType,
  handleMenuClose,
  postToEditType,
  postToEditTitle,
  postToEditSub,
  postToEditId,
  textSubmission,
  linkSubmission,
  fromSubreddit,
  handleTabChange,
}) => {
  const [open, setOpen] = useState(false);
  const [postType, setPostType] = useState("Text");
  // const user = useSelector((state) => state.user);
  // const simpleuser = store.getState();

  // const user = simpleuser.user;

  const [user, setUser] = useState(null);

  useEffect(() => {
    // Retrieve user data from sessionStorage
    const storedUserData = sessionStorage.getItem("userData");
    // Parse JSON data if it exists
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUser(parsedUserData);
    }
  }, []);

  const classes = useDialogStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTextPost = () => {
    setPostType("Text");
    handleClickOpen();
  };

  const handleImagePost = () => {
    setPostType("Image");
    handleClickOpen();
  };

  const handleLinkPost = () => {
    setPostType("Link");
    handleClickOpen();
  };

  const handleMenuClick = () => {
    handleClickOpen();
    handleMenuClose();
  };

  if (!user) {
    return null;
  }

  return (
    <div>
      {console.log(user.user, "CHECK THE THE USER DATA ----------")}
      {actionType === "edit" ? (
        <MenuItem onClick={handleMenuClick}>
          <ListItemIcon>
            <EditIcon style={{ marginRight: 7 }} />
            Edit Post
          </ListItemIcon>
        </MenuItem>
      ) : isMobile ? (
        <HideOnScroll>
          <Fab
            className={classes.fab}
            color="primary"
            onClick={handleClickOpen}
          >
            <PostAddIcon />
          </Fab>
        </HideOnScroll>
      ) : (
        <Paper variant="outlined" className={classes.createPostWrapper}>
          {user && user.user ? (
            <Avatar
              src={
                user.user.profilePic
                  ? `https://askportia.s3.amazonaws.com/${user.user.profilePic}`
                  : "https://askportia.s3.amazonaws.com/default-avatar.png"
              }
              alt="User Profile Picture"
            />
          ) : (
            <Avatar className={classes?.defaultAvatar}>
              {/* Assuming you want to show the first letter of the username if no avatar is available and the user is not the author. 
           Ensure you have `user.username` or a similar property available. 
           Uncomment and adjust the following line as needed. */}
              {/* {user?.username?.[0].toUpperCase()} */}
            </Avatar>
          )}

          <Button
            color="primary"
            variant="outlined"
            onClick={handleTextPost}
            fullWidth
            className={classes.createBtn}
            startIcon={<PostAddIcon />}
            size="large"
          >
            Create Post
          </Button>
          <div className={classes.iconGroup}>
            <IconButton onClick={handleImagePost}>
              <ImageIcon color="primary" />
            </IconButton>
            {/* <IconButton onClick={handleLinkPost}>
              <LinkIcon color="primary" />
            </IconButton> */}
          </div>
        </Paper>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle onClose={handleClose}>
          {actionType === "edit" ? "Update your post" : "Add a new post"}
        </DialogTitle>
        <DialogContent>
          <PostForm
            actionType={actionType}
            postType={postType}
            postToEditType={postToEditType}
            postToEditTitle={postToEditTitle}
            postToEditSub={postToEditSub}
            postToEditId={postToEditId}
            textSubmission={textSubmission}
            linkSubmission={linkSubmission}
            fromSubreddit={fromSubreddit}
            handleClose={handleClose}
            handleTabChange={handleTabChange}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddPostModal;
