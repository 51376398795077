import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import { Country, State, City } from "country-state-city";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.css";
import logo from "../../assets/image/logo.png";
import accountverification from "../../assets/image/accountverification.svg";
import eyeshowicon from "../../assets/image/eye-show.svg";
import eyehideicon from "../../assets/image/eye-hide1.svg";
import { signUpSchema } from "../../schema";
import { postData, getData } from "../../api/api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";

const Signup = (args) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedCountry, setCountry] = useState(null);
  const [selectedState, setState] = useState(null);

  const [countryCode, setcountryCode] = useState(null);
  const [stateCode, setstateCode] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const maxDate = new Date();

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleCountryChange = (e) => {
    handleChange(e);

    const selectedIndex = e.target.selectedIndex;
    const selectedText = e.target.options[selectedIndex].id;
    setCountry(selectedText);
    setcountryCode(selectedText);
  };

  const handleState = (e) => {
    handleChange(e); // Call Formik's handleChange to update form state
    const selectedIndex = e.target.selectedIndex;
    const selectedText = e.target.options[selectedIndex].id;
    setState(selectedText);
    setstateCode(selectedText);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    city: "",
    state: "",
    age: "",
    zipCode: "",
    password: "",
    ReEnterPassword: "",
  };
  const DateInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <Input
        className="form-control w-100"
        type="date"
        value={format(value ? new Date(value) : new Date(), "yyyy-MM-dd")}
        onClick={onClick}
        ref={ref}
      />
    );
  });
  const {
    setFieldValue,
    values,
    handleBlur,
    touched,
    handleChange,
    errors,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      const updatedData = {
        ...values, // Spread the existing data
        countryCode: countryCode,
        stateCode: stateCode,
      };

      await postData(updatedData, "userSignup")
        .then((res) => {
          res.data.status ? toggle() : toast(res.data.message);
          setIsSubmitting(false);
        })
        .catch((err) => {
          toast(err);
          setIsSubmitting(false);
          //navigate("/Signup");
        });
    },
  });

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col md="5" className="p-0">
              <div className="loginlogo_div">
                <img class="loginlogoimg" src={logo} alt="" />
              </div>
            </Col>
            <Col className="loginfield_bg" md="7">
              <div className="loginmain_div">
                <div className="headlogin_div">
                  <h3 className="login_head">Sign up to Welfore Health</h3>
                  <p className="card-text">
                    Effortlessly manage your lifestyle and discover new ways to
                    stay healthy!
                  </p>
                </div>
                <Form onSubmit={handleSubmit}>
                  <input
                    type="hidden"
                    name="countryCode"
                    value={values.countryCode}
                  ></input>
                  <input
                    type="hidden"
                    name="stateCode"
                    value={values.stateCode}
                  ></input>

                  <Row>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="firstName"
                          placeholder="First Name"
                          type="text"
                          value={values.firstName}
                          onChange={handleChange}
                        />
                        {errors.firstName && touched.firstName ? (
                          <span className="from-error">{errors.firstName}</span>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="lastName"
                          placeholder="Last Name"
                          type="text"
                          id="click"
                          value={values.lastName}
                          onChange={handleChange}
                        />
                        {errors.lastName && touched.lastName ? (
                          <span className="from-error">{errors.lastName}</span>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group">
                        <Label className="form-label">Email Address</Label>
                        <Input
                          name="email"
                          placeholder="Email Address"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                          <span className="from-error">{errors.email}</span>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group">
                        <Label className="form-label">ZipCode</Label>
                        <Input
                          type="text"
                          name="zipCode"
                          value={values.zipCode}
                          placeholder="Zip code"
                          onChange={handleChange}
                        ></Input>
                        {errors.zipCode && touched.zipCode ? (
                          <span className="from-error">{errors.zipCode}</span>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group">
                        <Label className="form-label">Country</Label>
                        <Input
                          type="select"
                          name="country"
                          value={values.country}
                          onChange={handleCountryChange}
                        >
                          <option value="">Select Country</option>

                          {Country.getAllCountries().map((element, index) => (
                            <option
                              key={index}
                              value={element.name}
                              id={element.isoCode}
                            >
                              {element.name}
                            </option>
                          ))}
                        </Input>
                        {errors.zipCode && touched.zipCode ? (
                          <span className="from-error">{errors.country}</span>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col md="12" lg="6">
                      <FormGroup className="form-group">
                        <Label className="form-label">State</Label>
                        <Input
                          type="select"
                          name="state"
                          value={values.state}
                          onChange={handleState}
                        >
                          <option value="">Select State</option>
                          {State.getStatesOfCountry(selectedCountry).map(
                            (element, index) => (
                              <option
                                key={index}
                                value={element.name}
                                id={element.isoCode}
                              >
                                {element.name}
                              </option>
                            )
                          )}
                        </Input>
                        {errors.state && touched.state ? (
                          <span className="from-error">{errors.state}</span>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="6">
                      <FormGroup className="form-group">
                        <Label className="form-label">City</Label>
                        <Input
                          type="select"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                        >
                          <option value="">Select City</option>
                          {City.getCitiesOfState(
                            selectedCountry,
                            selectedState
                          ).map((element, index) => (
                            <option
                              key={index}
                              value={element.name}
                              id={element.name}
                            >
                              {element.name}
                            </option>
                          ))}
                        </Input>
                        {errors.city && touched.city ? (
                          <span className="from-error">{errors.city}</span>
                        ) : null}
                      </FormGroup>
                    </Col>

                    {/* <Col md="12" lg="6">
                      <FormGroup className="form-group">
                        <Label className="form-label">Date of Birth</Label>
                        <DatePicker
                          name="dob"
                          selected={values.dob}
                          placeholderText="MM/DD/YYYY"
                          onChange={(date) => setFieldValue("dob", date)}
                          onSelect={(date) => {
                            setFieldValue("dob", date);
                          }}
                          dateFormat="MM-dd-yyyy"
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          value={values.dob}
                          maxDate={maxDate}
                          isClearable={false}
                          className="form-control w-100"
                          // customInput={<DateInput />}
                        />
                        {errors.dob && touched.dob ? (
                          <span className="from-error">{errors.dob}</span>
                        ) : null}
                      </FormGroup>
                    </Col> */}
                    <Col md="12" lg="6">
                      <FormGroup className="form-group">
                        <Label className="form-label">Age</Label>
                        <Input
                          name="age"
                          placeholder="Age"
                          value={values.age}
                          type="text"
                          onChange={handleChange}
                        />
                        {errors.age && touched.age ? (
                          <span className="from-error">{errors.age}</span>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col md="12" lg="6">
                      <FormGroup className="form-group position-relative">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          placeholder="Password"
                          type={showPassword1 ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange}
                        />
                        <div
                          className="iconright"
                          onClick={togglePasswordVisibility1}
                        >
                          {showPassword1 ? (
                            <img src={eyeshowicon} alt="" />
                          ) : (
                            <img src={eyehideicon} alt="" />
                          )}
                        </div>
                        {errors.password && touched.password ? (
                          <span className="from-error">{errors.password}</span>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="12" lg="6">
                      <FormGroup className="form-group position-relative">
                        <Label className="form-label">Re-Enter Password</Label>
                        <Input
                          name="ReEnterPassword"
                          placeholder="Re-Enter Password"
                          type={showPassword2 ? "text" : "password"}
                          value={values.ReEnterPassword}
                          onChange={handleChange}
                        />
                        <div
                          className="iconright"
                          onClick={togglePasswordVisibility2}
                        >
                          {showPassword2 ? (
                            <img src={eyeshowicon} alt="" />
                          ) : (
                            <img src={eyehideicon} alt="" />
                          )}
                        </div>
                        {errors.ReEnterPassword && touched.ReEnterPassword ? (
                          <span className="from-error">
                            {errors.ReEnterPassword}
                          </span>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end">
                    <Link to="/" className="mt-4 me-2">
                      <Button className="cancel_btn">Cancel</Button>
                    </Link>
                    {/* <Link className="mt-4" onClick={toggle}> */}

                    <Button
                      className="login_btn mt-4"
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Signing.." : "Sign Up"}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal} centered toggle={toggle} {...args}>
        <ModalBody className="text-center requestmodal">
          <img class="iconrequest" src={accountverification} alt="" />
          <h2>Account Verification</h2>
          <p>
            {" "}
            Thank you for signing up! To complete the registration process,
            please check your email inbox for a verification link. Click the
            link to verify your account and gain full access.
          </p>

          <Link to="/">
            <Button color="primary" className="px-5 mt-2">
              Close
            </Button>
          </Link>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Signup;
