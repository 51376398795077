import React, { useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getData, postData } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import newlogo from "../../assets/image/newlogo.svg";
import newlogo2 from "../../assets/image/newlogo2.svg";
import calendaricon from "../../assets/image/calendaricon.svg";
import pdf from "../../assets/image/pdf.webp";
import pageimagedefault from "../../assets/image/fooddiar.jpg";
const FoodDiary = (args) => {
  const [pageimage, setPageimage] = useState(pageimagedefault);
  const [section, setSectionData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [allNotifications, setAllQuestion] = useState([]);
  const [token, setToken] = useState(null);
  const [QuestionGroup, setQuestionGroup] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [next, setNext] = useState(true);
  const [pre, setPrevious] = useState(false);
  const [showsubmit, setShowsubmit] = useState(false);
  const [requiredFields, setRequiredFileds] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [firstName, setFirstName] = useState(null);

  const [time0, setTime0] = useState(null);
  const [Place0, setPlace0] = useState(null);
  const [Activity0, setActivity0] = useState(null);
  const [FoodDrink0, setFoodDrink0] = useState(null);
  const [ServingPortion0, setServingPortion0] = useState(null);
  const [Calories0, setCalories0] = useState(null);

  const [time1, setTime1] = useState(null);
  const [Place1, setPlace1] = useState(null);
  const [Activity1, setActivity1] = useState(null);
  const [FoodDrink1, setFoodDrink1] = useState(null);
  const [ServingPortion1, setServingPortion1] = useState(null);
  const [Calories1, setCalories1] = useState(null);

  const [time2, setTime2] = useState(null);
  const [Place2, setPlace2] = useState(null);
  const [Activity2, setActivity2] = useState(null);
  const [FoodDrink2, setFoodDrink2] = useState(null);
  const [ServingPortion2, setServingPortion2] = useState(null);
  const [Calories2, setCalories2] = useState(null);

  const [time3, setTime3] = useState(null);
  const [Place3, setPlace3] = useState(null);
  const [Activity3, setActivity3] = useState(null);
  const [FoodDrink3, setFoodDrink3] = useState(null);
  const [ServingPortion3, setServingPortion3] = useState(null);
  const [Calories3, setCalories3] = useState(null);

  const [time4, setTime4] = useState(null);
  const [Place4, setPlace4] = useState(null);
  const [Activity4, setActivity4] = useState(null);
  const [FoodDrink4, setFoodDrink4] = useState(null);
  const [ServingPortion4, setServingPortion4] = useState(null);
  const [Calories4, setCalories4] = useState(null);

  const [time5, setTime5] = useState(null);
  const [Place5, setPlace5] = useState(null);
  const [Activity5, setActivity5] = useState(null);
  const [FoodDrink5, setFoodDrink5] = useState(null);
  const [ServingPortion5, setServingPortion5] = useState(null);
  const [Calories5, setCalories5] = useState(null);

  const [time6, setTime6] = useState(null);
  const [Place6, setPlace6] = useState(null);
  const [Activity6, setActivity6] = useState(null);
  const [FoodDrink6, setFoodDrink6] = useState(null);
  const [ServingPortion6, setServingPortion6] = useState(null);
  const [Calories6, setCalories6] = useState(null);

  const [time7, setTime7] = useState(null);
  const [Place7, setPlace7] = useState(null);
  const [Activity7, setActivity7] = useState(null);
  const [FoodDrink7, setFoodDrink7] = useState(null);
  const [ServingPortion7, setServingPortion7] = useState(null);
  const [Calories7, setCalories7] = useState(null);

  const [time8, setTime8] = useState(null);
  const [Place8, setPlace8] = useState(null);
  const [Activity8, setActivity8] = useState(null);
  const [FoodDrink8, setFoodDrink8] = useState(null);
  const [ServingPortion8, setServingPortion8] = useState(null);
  const [Calories8, setCalories8] = useState(null);

  const [time9, setTime9] = useState(null);
  const [Place9, setPlace9] = useState(null);
  const [Activity9, setActivity9] = useState(null);
  const [FoodDrink9, setFoodDrink9] = useState(null);
  const [ServingPortion9, setServingPortion9] = useState(null);
  const [Calories9, setCalories9] = useState(null);

  const [time10, setTime10] = useState(null);
  const [Place10, setPlace10] = useState(null);
  const [Activity10, setActivity10] = useState(null);
  const [FoodDrink10, setFoodDrink10] = useState(null);
  const [ServingPortion10, setServingPortion10] = useState(null);
  const [Calories10, setCalories10] = useState(null);

  const [time11, setTime11] = useState(null);
  const [Place11, setPlace11] = useState(null);
  const [Activity11, setActivity11] = useState(null);
  const [FoodDrink11, setFoodDrink11] = useState(null);
  const [ServingPortion11, setServingPortion11] = useState(null);
  const [Calories11, setCalories11] = useState(null);

  const [time12, setTime12] = useState(null);
  const [Place12, setPlace12] = useState(null);
  const [Activity12, setActivity12] = useState(null);
  const [FoodDrink12, setFoodDrink12] = useState(null);
  const [ServingPortion12, setServingPortion12] = useState(null);
  const [Calories12, setCalories12] = useState(null);

  const [userTimeActivity, setUserTimeActivity] = useState([]);
  const [fetchedDataTable, setFtchedData] = useState([]);
  const [totalCalories, setTotalCalories] = useState(0);

  const handleTimeChange = (e, index) => {
    const setterFunction = eval(`setTime${index}`);
    const value = e.target.value.replace(/[^0-9:]/g, "");
    setterFunction(value);
  };

  const handlePlaceChange = (e, index) => {
    const setterFunction = eval(`setPlace${index}`);
    const value = e.target.value;
    setterFunction(value);
  };

  const handleActivityChange = (e, index) => {
    const setterFunction = eval(`setActivity${index}`);
    const value = e.target.value;
    setterFunction(value);
  };

  const handleFoodDrinkChange = (e, index) => {
    const setterFunction = eval(`setFoodDrink${index}`);
    const value = e.target.value;
    setterFunction(value);
  };

  const handleServingPortionChange = (e, index) => {
    const setterFunction = eval(`setServingPortion${index}`);
    const value = e.target.value;
    setterFunction(value);
  };

  const handleCaloriesChange = (e, index) => {
    const setterFunction = eval(`setCalories${index}`);
    const value = e.target.value.replace(/\D/g, "");
    setterFunction(value);
  };

  const functionSetFirstName = (value) => {
    setFirstName(value.target.value);
  };

  const myDate = () => {
    const date = selectedDate;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding leading zero if needed
    const day = String(date.getDate()).padStart(2, "0"); // Adding leading zero if needed
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const storedData =
      localStorage.getItem("userData") || sessionStorage.getItem("userData");
    const parsedData = JSON.parse(storedData);

    if (parsedData.token) {
      setToken(parsedData.token);
    }
  }, []);

  useEffect(() => {
    try {
      const res = getData(`/fetchUserFoodDiary?date=${myDate()}`, token)
        .then((res) => {
          if (res.data.data.userTimeActivity) {
            const d = res.data.data.userTimeActivity;
            let totalCalories = 0;
            d.forEach((item, index) => {
              let setTime = eval(`setTime${index}`);
              let setPlace = eval(`setPlace${index}`);
              let setActivity = eval(`setActivity${index}`);
              let setFoodDrink = eval(`setFoodDrink${index}`);
              let setServingPortion = eval(`setServingPortion${index}`);
              let setCalories = eval(`setCalories${index}`);
              let calories = item.calories;
              // You can now use setTime, setPlace, setActivity, etc. to set state values
              setTime(item.time);
              setPlace(item.place);
              setActivity(item.activity);
              setFoodDrink(item.foodOrDrink);
              setServingPortion(item.servingPortion);
              setCalories(calories);
              totalCalories += parseInt(calories, 10);
            });

            setTotalCalories(totalCalories);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // setVideoData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const handleDateChange = async (date) => {
    setSelectedDate(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding leading zero if needed
    const day = String(date.getDate()).padStart(2, "0"); // Adding leading zero if needed
    const newDate = `${year}-${month}-${day}`;
    const storedData =
      localStorage.getItem("userData") || sessionStorage.getItem("userData");
    const parsedData = JSON.parse(storedData);

    if (parsedData.token) {
      try {
        const res = await getData(
          `/fetchUserFoodDiary?date=${newDate}`,
          parsedData.token
        )
          .then((res) => {
            for (let index = 0; index <= 11; index++) {
              let setTime = eval(`setTime${index}`);
              let setPlace = eval(`setPlace${index}`);
              let setActivity = eval(`setActivity${index}`);
              let setFoodDrink = eval(`setFoodDrink${index}`);
              let setServingPortion = eval(`setServingPortion${index}`);
              let setCalories = eval(`setCalories${index}`);

              // You can now use setTime, setPlace, setActivity, etc. to set state values
              setTime(null);
              setPlace(null);
              setActivity(null);
              setFoodDrink(null);
              setServingPortion(null);
              setCalories(null);
            }
            setTotalCalories(0);
            if (res?.data?.data) {
              if (res.data.data.userTimeActivity) {
                const d = res.data.data.userTimeActivity;
                if (d.length > 0) {
                  let totalCalories = 0;
                  d.forEach((item, index) => {
                    let setTime = eval(`setTime${index}`);
                    let setPlace = eval(`setPlace${index}`);
                    let setActivity = eval(`setActivity${index}`);
                    let setFoodDrink = eval(`setFoodDrink${index}`);
                    let setServingPortion = eval(`setServingPortion${index}`);
                    let setCalories = eval(`setCalories${index}`);
                    let calories = item.calories;
                    // You can now use setTime, setPlace, setActivity, etc. to set state values
                    setTime(item.time);
                    setPlace(item.place);
                    setActivity(item.activity);
                    setFoodDrink(item.foodOrDrink);
                    setServingPortion(item.servingPortion);
                    setCalories(calories);
                    totalCalories += parseInt(calories, 10);
                  });
                  setTotalCalories(totalCalories);
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });

        // setVideoData(res.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // useEffect(() => {
  //   console.log(userTimeActivity, "check this ----");
  //   saveDataPrcess();
  // }, [userTimeActivity]);

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      let userTimeActivityArray = [];
      let totalCalories = 0;
      for (let i = 0; i < 11; i++) {
        // Assuming `eval` functions return the necessary values correctly
        if (
          eval(`time${i}`) &&
          eval(`Place${i}`) &&
          eval(`Activity${i}`) &&
          eval(`FoodDrink${i}`) &&
          eval(`ServingPortion${i}`) &&
          eval(`Calories${i}`)
        ) {
          const calories = eval(`Calories${i}`);
          userTimeActivityArray.push({
            time: eval(`time${i}`),
            place: eval(`Place${i}`),
            activity: eval(`Activity${i}`),
            foodOrDrink: eval(`FoodDrink${i}`),
            servingPortion: eval(`ServingPortion${i}`),
            calories: calories,
          });
          totalCalories += parseInt(calories, 10);
        }
      }

      // Update the state with the new array
      setUserTimeActivity(userTimeActivityArray);
      setTotalCalories(totalCalories);
      // Use the array directly for further operations
      if (userTimeActivityArray.length > 0) {
        console.log(userTimeActivityArray);
        try {
          const res = await postData(
            {
              date: myDate(),
              userTimeActivity: userTimeActivityArray, // Use the array directly here
            },
            "insertUserFoodDiary",
            token
          );
          if (res.data.status) {
            toast.success(res.data.message);
          } else {
            toast.error("Validation failed.");
          }
        } catch (error) {
          console.log(error);
          toast.error("An error occurred while saving the data.");
        }
      } else {
        toast.error("Please fill at least one entry.");
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while processing the form.");
    }
  };

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="12">
            <div className="border-bottom pb-4">
              <h1>Food Diary</h1>
            </div>
          </Col>
        </Row>
        <div class="breadcum mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/Questionnaire">My Library</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/Questionnaire">Questionnaire</Link>
            </BreadcrumbItem>

            <BreadcrumbItem active>Food Diary</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div className="dailymealplan">
          <Row className="align-items-center mb-3">
            <Col md={12} className="dailyhead">
              <img
                style={{ width: "100%", height: "992px" }}
                className="imagforfood"
                src={pageimage}
              />
            </Col>
          </Row>
          <Row className="align-items-center mb-3">
            <Col md={8} className="dailyhead">
              <h2>Create your daily meal plan</h2>
              <p className="mb-0">
                Start with the Minimum Daily Servings <b>(C)</b> to create your
                Daily Servings <b>(E)</b> for each Food Group <b>(B)</b>
              </p>
            </Col>
            <Col md={4} className="text-end">
              <img src={newlogo} className="img-fluid " alt="" />
            </Col>
          </Row>

          <div className="table-responsive">
            <table className="table tablescustom table-bordered mb-0">
              <thead>
                <tr>
                  <th>A</th>
                  <th>B</th>
                  <th>C</th>
                  <th>D</th>
                  <th>E</th>
                  <th>F</th>
                  <th>G</th>
                </tr>
                <tr>
                  <td>Fuel Nutrient Source</td>
                  <td>Food Groups</td>
                  <td>Minimum Number of Daily Servings</td>
                  <td>Calories provided by the minimum</td>
                  <td>Decide Your Daily Servings for each Food Group</td>
                  <td>Multiply Your Calories per Serving</td>
                  <td>Add Up Your Total Calories</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan="5" className="text-start">
                    Carb <br />
                    Sources
                  </td>
                  <td className="text-start">Cereal/Grains</td>
                  <td>4</td>
                  <td>320</td>
                  <td></td>
                  <td>x 80</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td className="text-start">Starchy Vegetables</td>
                  <td>2</td>
                  <td>160</td>
                  <td></td>
                  <td>x 80</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td className="text-start">Fruits</td>
                  <td>4</td>
                  <td>240</td>
                  <td></td>
                  <td>x 60</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td className="text-start">Low Fat Milk</td>
                  <td>2</td>
                  <td>180</td>
                  <td></td>
                  <td>x 90</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td className="text-start">Non-Starchy Vegetables</td>
                  <td>6</td>
                  <td>150</td>
                  <td></td>
                  <td>x 25</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td className="text-start">Protein</td>
                  <td className="text-start">Meat or Meat Substitutes</td>
                  <td>6 oz.</td>
                  <td>450</td>
                  <td></td>
                  <td>x 75</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td className="text-start">Fat</td>
                  <td className="text-start">Fat (margarine, butter, oil)</td>
                  <td>3</td>
                  <td>135</td>
                  <td></td>
                  <td>x 45</td>
                  <td>0</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3" className="text-center">
                    Minimum Total Calories for the Day
                  </td>
                  <td>1635</td>
                  <td colspan="2" className="text-center">
                    Your Total Calories
                  </td>
                  <td>0</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="tabledailybottom">
            <small>
              <i>
                Based on the American Heart and Diabetes Associations
                recommendations.
              </i>
            </small>
            <p>
              <span>Meal planning tips:</span> 1) Plan your meals and snacks{" "}
              <b>at least a day in advance</b>. 2) Create your grocery shopping
              list based on your meal plan. 3) Control your food portions. Size
              matters.
            </p>
          </div>
        </div>

        <form onSubmit={onSubmitHandler} id="Food_Form">
          <div className="dailyfoodjournal">
            <Row>
              <Col md={7} className="dailyhead">
                <h2>Daily Food Journal</h2>
              </Col>
              <Col md={5} className="text-end">
                <FormGroup className="form-group mb-0 position-relative">
                  <img
                    src={calendaricon}
                    className="img-fluid  imgcalendar position-absolute"
                    alt=""
                  />
                  <DatePicker
                    selected={selectedDate}
                    placeholderText="Date:"
                    value={firstName}
                    onChange={handleDateChange}
                    className="form-control w-100"
                    dateFormat="MM/dd/yyyy"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="table-responsive">
              <table className="table tablescustom tablecustom2 table-bordered mb-0">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Place</th>
                    <th>Activity</th>
                    <th width="25%">
                      Food or Drink
                      <small className="d-block">
                        (include “extras” like dressings, dips, sauces, cream,
                        sugar, etc.)
                      </small>
                    </th>
                    <th width="15%">
                      Serving Portion
                      <small className="d-block">
                        (actual size, weight or volume)
                      </small>
                    </th>
                    <th>Calories</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                  ].map((season, index) => (
                    <tr key={index}>
                      <td>
                        <textarea
                          value={
                            eval(`time${index}`) ? eval(`time${index}`) : ""
                          }
                          onChange={(e) => handleTimeChange(e, index)}
                          placeholder="Enter time (hh:mm)"
                        ></textarea>
                      </td>
                      <td>
                        <textarea
                          value={
                            eval(`Place${index}`) ? eval(`Place${index}`) : ""
                          }
                          onChange={(e) => handlePlaceChange(e, index)}
                        ></textarea>
                      </td>
                      <td>
                        <textarea
                          value={
                            eval(`Activity${index}`)
                              ? eval(`Activity${index}`)
                              : ""
                          }
                          onChange={(e) => handleActivityChange(e, index)}
                        ></textarea>
                      </td>
                      <td>
                        <textarea
                          value={
                            eval(`FoodDrink${index}`)
                              ? eval(`FoodDrink${index}`)
                              : ""
                          }
                          onChange={(e) => handleFoodDrinkChange(e, index)}
                        ></textarea>
                      </td>
                      <td>
                        <textarea
                          value={
                            eval(`ServingPortion${index}`)
                              ? eval(`ServingPortion${index}`)
                              : ""
                          }
                          onChange={(e) => handleServingPortionChange(e, index)}
                        ></textarea>
                      </td>
                      <td>
                        <textarea
                          value={
                            eval(`Calories${index}`)
                              ? eval(`Calories${index}`)
                              : ""
                          }
                          onChange={(e) => handleCaloriesChange(e, index)}
                        ></textarea>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="text-end calories">
              <h2>
                Total Calories <span className="separatorright">|</span>{" "}
                <span className="caloriestotal ps-lg-5">{totalCalories}</span>
              </h2>
            </div>
          </div>
          <input
            type="submit"
            className="btn btn-info"
            style={{ backgroundColor: "#0a91b1", color: "#ffff" }}
          ></input>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};

export default FoodDiary;
