import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Pagination,
  Breadcrumb,
  BreadcrumbItem,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-feather";
import { getData } from "../../api/api";
import CustomPagination from "../CustomPagination";

const Recipes = (args) => {
  const [recipes, setRecipes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recipesPerPage = 6; // Number of recipes to display per page
  const [searchTerm, setSearchTerm] = useState("");

  const filteredRecipes = recipes.filter((recipe) =>
    recipe.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await getData("adminGetRecipeList");
  //       setRecipes(res.data.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  const [truncatedContent, setTruncatedContent] = useState("");

  // Function to truncate content
  const truncateContent = (content, maxLength) => {
    if (content.length > maxLength) {
      return content.substring(0, maxLength) + "...";
    }
    return content;
  };
  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        // Replace 'https://yourwordpresssite.com/wp-json/wp/v2/recipes' with the actual endpoint for WP Recipe Maker recipes
        const response = await fetch(
          "https://showcase.indiit.com/welfore/wp-json/custom/v1/posts/"
        );
        const data = await response.json();
        setRecipes(data);
      } catch (error) {
        console.error("Error fetching recipes:", error);
      }
    };

    fetchRecipes();
  }, []);

  // Calculate the indexes of the first and last recipes for the current page
  const indexOfLastRecipe = currentPage * recipesPerPage;
  const indexOfFirstRecipe = indexOfLastRecipe - recipesPerPage;
  const currentRecipes = filteredRecipes.slice(
    indexOfFirstRecipe,
    indexOfLastRecipe
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="12">
            <div className="border-bottom pb-4">
              <h1>Recipes</h1>
              <p>
                Family-Friendly Recipes: Directions to prepare delicious meals
                that are kid-friendly and can involve the entire family in the
                cooking process.
              </p>
            </div>
          </Col>
        </Row>
        <div class="breadcum mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link>My Library</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Recipes</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      <Row>
        <input
          type="text"
          placeholder="Search recipes..."
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setCurrentPage(1); // Reset to first page on search
          }}
          value={searchTerm}
          className="form-control"
        />
        {currentRecipes.map((recipe, index) => (
          <Col md="4" lg="4" sm="6" className="colp_10" key={index}>
            <div className="videosmain_div">
              <div className="videos_img">
                <img
                  className="videoimg"
                  src={
                    recipe.image != false
                      ? recipe.image
                      : "https://www.vknow.in/images/thumbs/default-image_600.png"
                  }
                  alt=""
                />
              </div>
              <div className="videoshead_div">
                {/* <h3>{recipe.title}</h3> */}
                <h3
                  className="recipetop_name"
                  dangerouslySetInnerHTML={{ __html: recipe.title }}
                ></h3>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: truncateContent(recipe.content, 120),
                }}
                className="recipedtl_desc"
              ></div>
              <Link
                className="video_view_link"
                to={`/RecipeDetails/${recipe.id}`}
              >
                Read More <ArrowRight size={16} />
              </Link>
            </div>
          </Col>
        ))}
        {/* {currentRecipes.map((recipe, index) => (
          <Col md="4" lg="4" sm="6" className="colp_10" key={index}>
            <div className="videosmain_div">
              <div className="videos_img">
                <img
                  className="videoimg"
                  src={
                    recipe.recipeImage[0]
                      ? `https://askportia.s3.amazonaws.com/${recipe.recipeImage[0]}`
                      : "https://www.vknow.in/images/thumbs/default-image_600.png"
                  }
                  alt=""
                />
              </div>
              <div className="videoshead_div">
                <h3>{recipe.recipeName}</h3>
              </div>

              <p className="recipedtl_desc">{recipe.description}</p>
              <Link
                className="video_view_link"
                to={`/RecipeDetails/${recipe._id}`}
              >
                Read More <ArrowRight size={16} />
              </Link>
            </div>
          </Col>
        ))} */}
      </Row>
      {/* <Pagination>
        {Array.from({
          length: Math.ceil(filteredRecipes.length / recipesPerPage),
        }).map((item, index) => (
          <PaginationItem key={index} active={index + 1 === currentPage}>
            <PaginationLink onClick={() => paginate(index + 1)}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
      </Pagination> */}
      <CustomPagination
        currentPage={currentPage}
        totalItems={filteredRecipes.length}
        pageSize={recipesPerPage}
        paginate={paginate}
      />
    </>
  );
};

export default Recipes;
