import { postData } from "../api/api";

const initialState = {
  token: null,
  user: null,
  isAuthenticated: null,
};

export const authActions = (state = initialState, action) => {
  console.log(action?.payload?.token, "checking user actions here!");
  console.log(action?.payload?.user, "checking user actions here!");
  if (action.type == "login") {
    state = {
      token: action.payload.token,
      user: action.payload.user,
      isAuthenticated: true,
    };
  }
  return state;
};
