import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
  AreaChart,
  Area,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { MoreVertical } from "react-feather";
import { Link } from "react-router-dom";
import banner_img1 from "../../assets/image/banner_img2.svg";
import filter_icon from "../../assets/image/img/filter_icon.svg";
import { getData } from "../../api/api";
import store from "../../store";
const databar = [
  {
    name: "M",
    DrinkingWater: 6,
  },
  {
    name: "T",
    DrinkingWater: 5,
  },
  {
    name: "W",
    DrinkingWater: 3,
  },
  {
    name: "T",
    DrinkingWater: 5,
  },
  {
    name: "F",
    DrinkingWater: 3,
  },
  {
    name: "S",
    DrinkingWater: 6,
  },
  {
    name: "S",
    DrinkingWater: 5,
  },
];
const Rainbowdata = [
  {
    name: "M",
    DrinkingWater: 3,
  },
  {
    name: "T",
    DrinkingWater: 4,
  },
  {
    name: "W",
    DrinkingWater: 5,
  },
  {
    name: "T",
    DrinkingWater: 2,
  },
  {
    name: "F",
    DrinkingWater: 2,
  },
  {
    name: "S",
    DrinkingWater: 6,
  },
  {
    name: "S",
    DrinkingWater: 6,
  },
];
const dataGrams = [
  {
    name: "M",
    DrinkingWater: 6,
  },
  {
    name: "T",
    DrinkingWater: 2,
  },
  {
    name: "W",
    DrinkingWater: 3,
  },
  {
    name: "T",
    DrinkingWater: 5,
  },
  {
    name: "F",
    DrinkingWater: 3,
  },
  {
    name: "S",
    DrinkingWater: 1,
  },
  {
    name: "S",
    DrinkingWater: 1,
  },
];
const dataExercise = [
  {
    name: "M",
    DrinkingWater: 3,
  },
  {
    name: "T",
    DrinkingWater: 5,
  },
  {
    name: "W",
    DrinkingWater: 3,
  },
  {
    name: "T",
    DrinkingWater: 3,
  },
  {
    name: "F",
    DrinkingWater: 3,
  },
  {
    name: "S",
    DrinkingWater: 6,
  },
  {
    name: "S",
    DrinkingWater: 5,
  },
];
const dataMindset = [
  {
    name: "M",
    DrinkingWater: 4,
  },
  {
    name: "T",
    DrinkingWater: 3,
  },
  {
    name: "W",
    DrinkingWater: 3,
  },
  {
    name: "T",
    DrinkingWater: 2,
  },
  {
    name: "F",
    DrinkingWater: 4,
  },
  {
    name: "S",
    DrinkingWater: 6,
  },
  {
    name: "S",
    DrinkingWater: 5,
  },
];
const dataWeight = [
  {
    name: "M",
    DrinkingWater: 80,
  },
  {
    name: "T",
    DrinkingWater: 110,
  },
  {
    name: "W",
    DrinkingWater: 100,
  },
  {
    name: "T",
    DrinkingWater: 200,
  },
  {
    name: "F",
    DrinkingWater: 60,
  },
  {
    name: "S",
    DrinkingWater: 65,
  },
  {
    name: "S",
    DrinkingWater: 85,
  },
];
const dataBloodpressure = [
  {
    name: "M",
    Systolic: 140,
    Diastolic: 90,
  },
  {
    name: "T",
    Systolic: 125,
    Diastolic: 85,
  },
  {
    name: "W",
    Systolic: 135,
    Diastolic: 95,
  },
  {
    name: "T",
    Systolic: 165,
    Diastolic: 80,
  },
  {
    name: "F",
    Systolic: 140,
    Diastolic: 85,
  },
  {
    name: "S",
    Systolic: 150,
    Diastolic: 95,
  },
  {
    name: "S",
    Systolic: 145,
    Diastolic: 80,
  },
];

const DrinkingWaterBar = (props) => {
  const { x, y, width, height } = props;

  // Ensure height is not negative
  const validHeight = Math.max(0, height);

  return (
    <g>
      <rect
        x={x}
        y={y + 5}
        width={width}
        height={validHeight}
        rx={5}
        ry={5}
        fill="#008CB2"
      />
    </g>
  );
};

// Repeat the same pattern for other custom Bar components

const RainbowBar = (props) => {
  const { x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y + 5}
        width={width}
        height={height - 5}
        rx={5}
        ry={5}
        fill="#87D234"
      />
    </g>
  );
};
const GramsBar = (props) => {
  const { x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y + 5}
        width={width}
        height={height - 5}
        rx={5}
        ry={5}
        fill="#415494"
      />
    </g>
  );
};
const ExerciseBar = (props) => {
  const { x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y + 5}
        width={width}
        height={height - 5}
        rx={5}
        ry={5}
        fill="#ECA637"
      />
    </g>
  );
};
const WeightBar = (props) => {
  const { x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y + 5}
        width={width}
        height={height - 5}
        rx={5}
        ry={5}
        fill="#008CB2"
      />
    </g>
  );
};

const Dashboard = (args) => {
  const navigate = useNavigate();
  const [selectweek, setSelectweek] = useState("selectweek1");
  const [token, setToken] = useState(null);
  const [databar, setDatabar] = useState([]);
  const [Rainbowdata, setraibow] = useState([]);
  const [dataGrams, setFiber] = useState([]);
  const [dataBloodpressure, setPressure] = useState([
    {
      name: "M",
      Systolic: 140,
      Diastolic: 90,
    },
    {
      name: "T",
      Systolic: 125,
      Diastolic: 85,
    },
    {
      name: "W",
      Systolic: 135,
      Diastolic: 95,
    },
    {
      name: "T",
      Systolic: 165,
      Diastolic: 80,
    },
    {
      name: "F",
      Systolic: 140,
      Diastolic: 85,
    },
    {
      name: "S",
      Systolic: 150,
      Diastolic: 95,
    },
    {
      name: "S",
      Systolic: 145,
      Diastolic: 80,
    },
  ]);
  const [dataWeight, setWeight] = useState([]);
  const [pressured, setPressured] = useState(null);

  useEffect(() => {
    const fetchtoken = async () => {
      const storedData =
        localStorage.getItem("userData") || sessionStorage.getItem("userData");
      // Parse the JSON string back to an object
      const parsedData = JSON.parse(storedData);
      console.log(parsedData);
      if (parsedData.token) {
        fetchData(parsedData.token);
      } else {
        navigate("/");
      }
    };
    fetchtoken();
  }, []);

  // Function to fetch data
  const fetchData = async (token) => {
    try {
      const res = await getData("dashboard?filter=week", token);
      if (res?.data?.connectedwithingslink) {
        // setPressured(res?.data?.connectedwithingslink);
      }

      const transformedData = res.data.data.map((item) => ({
        name: item._id,
        DrinkingWater: item.totalWaterIntake,
      }));

      const rainbowcolor = res.data.data.map((item) => ({
        name: item._id,
        DrinkingWater: item.colorsEaten,
      }));

      const Fiber = res.data.data.map((item) => ({
        name: item._id,
        DrinkingWater: item.fiberEaten,
      }));

      const Pressure = res?.data?.weeklybpSystolicData?.map((item) => ({
        name: item.day,
        Systolic: item.weight,
        DrinkingWater: +item.weight,
      }));

      const Weight = res.data.data.map((item) => ({
        name: item._id,
        DrinkingWater: item.weightInLbs,
      }));

      setDatabar(transformedData);
      setraibow(rainbowcolor);
      setFiber(Fiber);
      //  setPressure(Pressure);
      setWeight(Weight);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <h1>Dashboard</h1>
            <p>
              Every day is a new step towards your health. Open your dashboard
              to celebrate your daily health progress.
            </p>
          </Col>
        </Row>
      </div>
      <Row className="mb-4">
        <Col lg="12">
          <div className="mealbanner_div dashboardbanner_div">
            <Row>
              <Col sm="8" md="8" className="align-self-center">
                <div className="mealbanner_content">
                  <h2 className="title">See how you’re doing!</h2>
                  <p className="subtitle">
                    Here you can see a breakdown of your key stats and figures
                    to help keep you motivated and feeling good!
                  </p>
                </div>
              </Col>
              <Col
                sm="4"
                md={{ size: 3, offset: 1 }}
                className="mealbanner_img pt-2"
              >
                <img className="banner_img" src={banner_img1} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md="12" className="mb-4">
          <div className="homefilter_div">
            <Input
              type="select"
              name="selectweek"
              className="d-inline-block w-auto"
              value={selectweek}
              onChange={(e) => setSelectweek(e.target.value)}
            >
              <option value="select">Select One</option>
              <option value="selectweek1">This Week</option>
              <option value="selectweek2">This Month</option>
              <option value="selectweek3">This Year</option>
            </Input>
            <Button type="button" className="homefilter_btn">
              <img src={filter_icon} alt="" /> Filters
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="4" className="mb-3">
          <div className="dash_chart_box">
            <div className="graphhead_div">
              <h2 className="graph_head">My Beverage Intake</h2>
              <Link className="chart_dots_btn">
                <MoreVertical />
              </Link>
            </div>
            <ResponsiveContainer width="100%" height={250} className="graphdiv">
              <BarChart
                data={databar}
                margin={{
                  top: 0,
                  right: 0,
                  left: -45,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                <YAxis tick={{ fontSize: 10 }} />
                <Bar
                  dataKey="DrinkingWater"
                  fill="#008CB2"
                  shape={<DrinkingWaterBar />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col lg="4" className="mb-3">
          <div className="dash_chart_box">
            <div className="graphhead_div">
              <h2 className="graph_head">How I am Doing with Colors</h2>
              <Link className="chart_dots_btn">
                <MoreVertical />
              </Link>
            </div>
            <ResponsiveContainer width="100%" height={250} className="graphdiv">
              <BarChart
                data={Rainbowdata}
                margin={{
                  top: 0,
                  right: 0,
                  left: -45,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                <YAxis tick={{ fontSize: 10 }} />
                <Bar
                  dataKey="DrinkingWater"
                  fill="#008CB2"
                  shape={<RainbowBar />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col lg="4" className="mb-3">
          <div className="dash_chart_box">
            <div className="graphhead_div">
              <h2 className="graph_head">My Fiber Intake</h2>
              <Link className="chart_dots_btn">
                <MoreVertical />
              </Link>
            </div>
            <ResponsiveContainer width="100%" height={250} className="graphdiv">
              <BarChart
                data={dataGrams}
                margin={{
                  top: 0,
                  right: 0,
                  left: -45,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                <YAxis tick={{ fontSize: 10 }} />
                <Bar
                  dataKey="DrinkingWater"
                  fill="#008CB2"
                  shape={<GramsBar />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col lg="8" className="mb-3">
          <div className="dash_chart_box">
            <div className="graphhead_div">
              <h2 className="graph_head">How I Have Been Feeling</h2>
              <Link className="chart_dots_btn">
                <MoreVertical />
              </Link>
            </div>
            <ResponsiveContainer width="100%" height={250} className="graphdiv">
              <AreaChart
                data={dataMindset}
                margin={{
                  top: 0,
                  right: 0,
                  left: -45,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                <YAxis tick={{ fontSize: 10 }} />
                <Area
                  type="monotone"
                  dataKey="DrinkingWater"
                  stroke="#CF6060"
                  fill="#CF6060"
                  fillOpacity={0.12}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col lg="4" className="mb-3">
          <div className="dash_chart_box">
            <div className="graphhead_div">
              <h2 className="graph_head">How Much Have I Been Moving?</h2>
              <Link className="chart_dots_btn">
                <MoreVertical />
              </Link>
            </div>
            <ResponsiveContainer width="100%" height={250} className="graphdiv">
              <BarChart
                data={dataExercise}
                margin={{
                  top: 0,
                  right: 0,
                  left: -45,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                <YAxis tick={{ fontSize: 10 }} />
                <Bar
                  dataKey="DrinkingWater"
                  fill="#008CB2"
                  shape={<ExerciseBar />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Col>
        <Col lg="6" className="mb-3">
          <div className="dash_chart_box">
            <div className="graphhead_div">
              <h2 className="graph_head">My Blood Pressure Trends</h2>
              <Link className="chart_dots_btn">
                <MoreVertical />
              </Link>
            </div>

            <ResponsiveContainer width="100%" height={250} className="graphdiv">
              <AreaChart
                data={dataBloodpressure}
                margin={{
                  top: 0,
                  right: 0,
                  left: -35,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                <YAxis tick={{ fontSize: 10 }} />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="Systolic"
                  stroke="#87D234"
                  fill="#87D234"
                  fillOpacity={0.12}
                />
                <Area
                  type="monotone"
                  dataKey="Diastolic"
                  stroke="#008CB2"
                  fill="#008CB2"
                  fillOpacity={0.12}
                />
              </AreaChart>
            </ResponsiveContainer>
            {/*           
              <div className="accountbox">
                <p>if you don't have a withings account </p>
                <a
                  target="_blank"
                  href="https://www.withings.com/uk/en/account/login"
                  className="btn btn-primary d-inline-flex align-items-center justify-content-center"
                >
                  Click Here
                </a>
                <div className="clearfix"></div>
                <span className="ortext">or</span>
                <div className="clearfix"></div>
                <p>click below if already have an account</p>
                <a
                  href={pressured}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary d-inline-flex align-items-center justify-content-center"
                >
                  Get Data
                </a>
              </div> */}
          </div>
        </Col>
        <Col lg="6" className="mb-3">
          <div className="dash_chart_box">
            <div className="graphhead_div">
              <h2 className="graph_head">My Body Weight Trends</h2>
              <Link className="chart_dots_btn">
                <MoreVertical />
              </Link>
            </div>
            <ResponsiveContainer width="100%" height={250} className="graphdiv">
              <BarChart
                data={dataWeight}
                margin={{
                  top: 0,
                  right: 0,
                  left: -35,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                <YAxis tick={{ fontSize: 10 }} />
                <Bar
                  dataKey="DrinkingWater"
                  fill="#008CB2"
                  shape={<WeightBar />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
