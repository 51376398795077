import React from "react";

import { Button } from "@material-ui/core";
import { usePostListStyles } from "../styles/muiStyles";
import AutorenewIcon from "@material-ui/icons/Autorenew";

const LoadMoreButton = ({ handleLoadPosts, loading }) => {
  const classes = usePostListStyles();

  return (
    <div className={classes.loadBtnWrapper}>
      <Button
        size="large"
        onClick={handleLoadPosts}
        startIcon={<AutorenewIcon />}
        className={[classes.loadBtn, "btn btn-primary"]}
        disabled={loading}
      >
        {loading ? "Loading..." : "Load more"}
      </Button>
    </div>
  );
};

export default LoadMoreButton;
