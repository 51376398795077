import React from "react";
import { Row, Col } from "reactstrap";
import check from "../../assets/image/success.svg";
const AboutApplication = () => {
  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <h1>About Application</h1>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisici elit</p> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AboutApplication;
