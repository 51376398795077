import React from "react";
import Header from "../../pages/Include/Header";
const Layout2 = ({ children }) => {
  return (
    <>
      <div className="withoutsidebar">
        <Header />
        <div className="main">
          <div className="maincontent_div">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout2;
