import React, { useState } from "react";
import {
  Row,
  Col,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Breadcrumb,
  BreadcrumbItem,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import csvsample from "../../assets/pdf/Updated_Recipes.pdf";
import { Link } from "react-router-dom";
const FAQs = (props) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const handleDownload = async () => {
    try {
      // Fetch the PDF file
      const response = await fetch(csvsample);

      // Get the response as Blob
      const pdfBlob = await response.blob();

      // Create a temporary URL for the Blob object
      const url = URL.createObjectURL(pdfBlob);

      // Create a hidden anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = "ebook.pdf"; // Set the filename
      a.style.display = "none";

      // Append the anchor element to the body
      document.body.appendChild(a);

      // Simulate a click on the anchor element
      a.click();

      // Clean up
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <h1>FAQs</h1>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisici elit</p> */}
          </Col>
          <div class="breadcum mt-3">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link>Live Support</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>FAQ's</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Row>
      </div>
      <Accordion open={open} toggle={toggle}>
        <AccordionItem className="mb-3">
          <AccordionHeader targetId="1">
            How Can I Contact Customer Support?
          </AccordionHeader>
          <AccordionBody accordionId="1">
            <p>
              Please reach out to us via{" "}
              <a href="#">Nutriiton@WelForeHealth.com</a> or see the chat icon
              in the lower left of your device.
            </p>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem className="mb-3">
          <AccordionHeader targetId="2">
            How Do I update my account information:
          </AccordionHeader>
          <AccordionBody accordionId="2">
            <p>Go to Account Settings (gear icon)</p>
            <p>Make any changes that you require and hit SAVE to enable.</p>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem className="mb-3">
          <AccordionHeader targetId="3">
            What are some ways to help me drink more water?
          </AccordionHeader>
          <AccordionBody accordionId="3">
            <p>
              Infuse your water with cucumber slices, mint, your favorite fruit
              etc
            </p>
            <p>
              <a
                href="https://askportia.s3.amazonaws.com/Updated_Recipes.pdf"
                target="_blank"
              >
                Download
              </a>{" "}
              this ebook for more suggestions you may not have considered before
              :
            </p>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default FAQs;
