import React from "react";

import source3 from "../../assets/image/chat.svg";

import source1 from "../../assets/image/emailus.svg";
import source2 from "../../assets/image/callus.svg";
import {
  Row,
  Col,
  Pagination,
  Breadcrumb,
  BreadcrumbItem,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";
const ContactUs = () => {
  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <h1>Contact Us</h1>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisici elit</p> */}
          </Col>
          <div class="breadcum mt-3">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link>Live Support</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Contact Us</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Row>
      </div>
      <Row className="match-height">
        <Col lg="3" sm="6" className="colp_10" id="openChatBtn">
          <div className="card mealplan_box">
            <a href="#">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source3}
                      height={50}
                    />
                  </div>
                  <h3>Chat Us</h3>
                  <p className="card-text">
                    See Icon in the Lower Right Corner. Just click to Connect.
                  </p>
                </div>
              </div>
            </a>
          </div>
        </Col>
        <Col lg="3" sm="6" className="colp_10">
          <div className="card mealplan_box">
            <a href="mailto:Hello@ WelForeHealth.com">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source1}
                      height={50}
                    />
                  </div>
                  <h3>Email Us</h3>
                  <p className="card-text">Hello@WelForeHealth.com</p>
                </div>
              </div>
            </a>
          </div>
        </Col>
        <Col lg="3" sm="6" className="colp_10">
          <div className="card mealplan_box">
            <a href="tel:+1-757-617-0312">
              <div className="card-body">
                <div className="d-block">
                  <div className="mealplan_img">
                    <img
                      className="borderad-none rounded-0"
                      src={source2}
                      height={50}
                    />
                  </div>
                  <h3>Call Us</h3>
                  <p className="card-text">+1-757-617-0312</p>
                </div>
              </div>
            </a>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ContactUs;
