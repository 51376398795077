import React, { useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getData, postData } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import newlogo from "../../assets/image/newlogo.svg";
import newlogo2 from "../../assets/image/newlogo2.svg";
import calendaricon from "../../assets/image/calendaricon.svg";
import pdf from "../../assets/image/pdf.webp";
import { useFormik } from "formik";
import { DiarySchema, SmartGoalSchema } from "../../schema";
import PopupModel from "../PopupModel";

const SmartGoals = (args) => {
  const [section, setSectionData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [allNotifications, setAllQuestion] = useState([]);
  const [token, setToken] = useState(null);
  const [QuestionGroup, setQuestionGroup] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [next, setNext] = useState(true);
  const [pre, setPrevious] = useState(false);
  const [showsubmit, setShowsubmit] = useState(false);
  const [requiredFields, setRequiredFileds] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [SmartGoal, setGoal] = useState(null);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const myToggle = () => {
    setModal(!modal);
    navigate("/Questionnaire");
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData("fecth_section");
        console.log(res.data.data);
        setSectionData(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const functionSetFirstName = (value) => {
    setFirstName(value.target.value);
  };

  useEffect(() => {
    const storedData =
      localStorage.getItem("userData") || sessionStorage.getItem("userData");
    const parsedData = JSON.parse(storedData);
    if (parsedData.token) {
      setToken(parsedData.token);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData(`fetchUserGoal`, token);
        setGoal(res.data.data.userGoal);
        setFieldValue("smartGoal", res.data.data.userGoal);
        setFieldValue("Achievable", res.data.data.Achievable);
        setFieldValue("Bound", res.data.data.Bound);
        setFieldValue("accomplishing", res.data.data.accomplishing);
        setFieldValue("track", res.data.data.track);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [token]);

  const initialValues = {
    smartGoal: "",
    track: "",
    Achievable: "",
    accomplishing: "",
    Bound: "",
  };
  const {
    values,
    handleBlur,
    touched,
    handleChange,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: SmartGoalSchema,
    onSubmit: async (values) => {
      console.log(values.smartGoal);
      var loginButtons = document.querySelectorAll(".login_btn"); // Selects all buttons with class 'login_btn'
      loginButtons.forEach(function (button) {
        button.disabled = true; // Disables each button
      });
      // Assuming you have a 'text' field

      await postData(
        {
          userGoal: values.smartGoal,
          track: values.track,
          Achievable: values.Achievable,
          accomplishing: values.accomplishing,
          Bound: values.Bound,
        },
        "insertUserGoal",
        token
      )
        .then((res) => {
          if (res.data.status) {
            setModal(true);
          } else {
            toast.error("Opration failed");
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  });

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="12">
            <div className="border-bottom pb-4">
              <h1>Smart Goals</h1>
            </div>
          </Col>
        </Row>
        <div class="breadcum mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link>My Library</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/Questionnaire">Questionnaire</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Smart Goals</BreadcrumbItem>
          </Breadcrumb>
        </div>

        <TabPane key={2} tabId={2}>
          <Row>
            <Col sm="12">
              <img src={pdf} className="img-fluid" alt="" />
            </Col>
          </Row>
          <Row className="px-lg-4">
            <Col sm="8">
              <form id="smartGoalForm" onSubmit={handleSubmit}>
                <FormGroup className="form-group mb-0 position-relative ps-lg-1">
                  <Label>What is your ONE Specific Goal?</Label>
                  <Input
                    id="exampleText"
                    className="textareacustom"
                    type="textarea"
                    name="smartGoal"
                    value={values.smartGoal}
                    onChange={handleChange}
                  />
                  {errors.smartGoal && touched.smartGoal ? (
                    <p className="from-error">{errors.smartGoal}</p>
                  ) : null}
                </FormGroup>
                <FormGroup className="form-group mb-0 position-relative ps-lg-1">
                  <Label>
                    Let's make it Measurable. How Often do you want to track it?
                  </Label>
                  <Input
                    id="exampleText"
                    className="textareacustom"
                    type="textarea"
                    name="track"
                    value={values.track}
                    onChange={handleChange}
                  />
                  {errors.track && touched.track ? (
                    <p className="from-error">{errors.track}</p>
                  ) : null}
                </FormGroup>
                <FormGroup className="form-group mb-0 position-relative ps-lg-1">
                  <Label>
                    Is this ONE Goal Achievable for you? What barriers, pain
                    points or challenges would you need to consider to make this
                    achievable?
                  </Label>
                  <Input
                    id="exampleText"
                    className="textareacustom"
                    type="textarea"
                    name="Achievable"
                    value={values.Achievable}
                    onChange={handleChange}
                  />
                  {errors.Achievable && touched.Achievable ? (
                    <p className="from-error">{errors.Achievable}</p>
                  ) : null}
                </FormGroup>
                <FormGroup className="form-group mb-0 position-relative ps-lg-1">
                  <Label>
                    How does accomplishing this ONE Goal connect with your
                    Overall Health Goals?
                  </Label>
                  <Input
                    id="exampleText"
                    className="textareacustom"
                    type="textarea"
                    name="accomplishing"
                    value={values.accomplishing}
                    onChange={handleChange}
                  />
                  {errors.accomplishing && touched.accomplishing ? (
                    <p className="from-error">{errors.accomplishing}</p>
                  ) : null}
                </FormGroup>
                <FormGroup className="form-group mb-0 position-relative ps-lg-1">
                  <Label>
                    How do you make it Time Bound? What is your Start Date and
                    your ideal End Date?
                  </Label>
                  <Input
                    id="exampleText"
                    className="textareacustom"
                    type="textarea"
                    name="Bound"
                    value={values.Bound}
                    onChange={handleChange}
                  />
                  {errors.Bound && touched.Bound ? (
                    <p className="from-error">{errors.Bound}</p>
                  ) : null}
                </FormGroup>
                <input type="submit" class="login_btn" />
              </form>
            </Col>
            <Col sm="4" className="text-end">
              <img src={newlogo2} className="img-fluid" alt="" />
            </Col>
          </Row>
        </TabPane>
        <ToastContainer />
      </div>
      <PopupModel
        modal={modal}
        toggle={myToggle}
        message="Smart Goal submit successfully."
        title="Smart Goal"
      />
    </>
  );
};

export default SmartGoals;
