import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

function MessageModel({ modal, toggle }) {
  const closeBtn = (
    <button
      className="close"
      type="button"
      onClick={toggle}
      style={{
        width: 35,
        height: 35,
        borderWidth: 0,
        background: "#b20000",
        borderRadius: "50%",
        position: "absolute",
        top: -16,
        right: -16,
        fontSize: 22,
        color: "#fff",
      }}
    >
      &times;
    </button>
  );

  return (
    <div>
      <Modal isOpen={modal} className={PropTypes.string} size="lg">
        <ModalHeader close={closeBtn} className="border-0 p-0"></ModalHeader>
        <ModalBody className="text-center messageModal_body">
          <h1>Welcome to Your Personalized Health Journey!</h1>
          <p>
            You’ve taken an important first step toward living a healthier,
            balanced life. At WelFore Health, we believe that your{" "}
            <strong>culture</strong> and <strong>traditions</strong> can work
            hand in hand with modern nutrition science to help you reach your
            wellness goals.
          </p>

          <p>
            By subscribing to <strong>AskWelFore</strong>, you’ll unlock a world
            of <strong>personalized support</strong> designed just for you. And
            with our <strong> 14-day free trial</strong>, you can explore all
            the tools you need to succeed—completely risk-free!
          </p>

          <p>
            <strong>Here’s what you can look forward to:</strong>
          </p>

          <ul>
            <li>
              <strong>Personalized Nutrition:</strong> Get meal plans tailored
              to your unique needs and <strong>cultural heritage</strong>, so
              you can eat the foods you love while improving your health.
            </li>
            <li>
              <strong>Real-Time Tracking:</strong> Monitor your progress—whether
              it’s meals, movement, or health metrics—all in one place, with
              updates that keep you on track.
            </li>
            <li>
              <strong>Expert Guidance:</strong> Access{" "}
              <strong>culturally relevant tips</strong>, wellness insights, and
              tools crafted by experts to support your journey.
            </li>
            <li>
              <strong>Community Support:</strong> Connect with others on a
              similar path, because achieving wellness is easier with a
              community of like-minded people by your side.
            </li>
          </ul>
          <p>
            <strong>Start Your Free 14-Day's Trial Now!</strong>
          </p>

          <p>
            Explore AskWelFore and see how personalized nutrition and real-time
            support can transform your health journey. There’s no commitment,
            and you’ll get full access to everything for two full weeks.
          </p>

          <p>
            {" "}
            <strong>
              Take the next step toward a healthier you—login or create your
              account now and start your free trial!
            </strong>
          </p>
          <p>We're here with you, every step of the way!</p>

          <div className="button-container">
            <a href="#" className="btn" onClick={toggle}>
              Get Started Now
            </a>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default MessageModel;
