import React, { useState, useEffect } from "react";
import VideoPlayer from "../videoplayer/Video";
import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { ChevronLeft, Clock, FileText } from "react-feather";
import { FaStar } from "react-icons/fa6";
import { LuCheckCircle } from "react-icons/lu";
import user1 from "../../assets/image/user1.jpg";
import videoimg1 from "../../assets/image/videoimg1.jpg";
import videoplayer from "../../assets/image/video.mp4";
import videoimg2 from "../../assets/image/videoimg2.jpg";
import videoimg3 from "../../assets/image/videoimg3.jpg";
import videoimg4 from "../../assets/image/videoimg4.jpg";
import videoimg5 from "../../assets/image/videoimg5.jpg";
import videoimg6 from "../../assets/image/videoimg6.jpg";
import videoimg7 from "../../assets/image/videoimg7.jpg";
import videoimg8 from "../../assets/image/videoimg8.jpg";
import videoimg9 from "../../assets/image/videoimg9.jpg";
import videoimg10 from "../../assets/image/videoimg10.jpg";
import videoimg11 from "../../assets/image/videoimg11.jpg";
import videoimg12 from "../../assets/image/videoimg12.jpg";
import playicon from "../../assets/image/videoplay_icon.svg";
import { getData } from "../../api/api";
const RecipeDetails = (args) => {
  const [video, setVideoData] = useState([]);
  const [videoLink, setVideoDataLink] = useState([]);
  const { id } = useParams();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await getData(`adminViewRecipe?recipeId=${id}`);
  //       console.log(res.data.data);
  //       setVideoData(res.data.data);
  //       //   console.log(transformedData);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   // Call the fetch token function
  //   fetchData();
  // }, []);
  const RecipeEquipmentList = ({ equipment }) => {
    return (
      <ul className="recipelist">
        {equipment.map((item) => (
          <li key={item.id}>
            <LuCheckCircle />
            {item?.amount} {item.unit} {item.name} {item?.notes}
          </li>
        ))}
      </ul>
    );
  };
  const Instruction = ({ equipment }) => {
    return (
      <ul className="recipelist">
        {equipment.map((item) => (
          <li key={item.uid}>
            <LuCheckCircle />
            <div
              dangerouslySetInnerHTML={{
                __html: item?.text,
              }}
            ></div>
          </li>
        ))}
      </ul>
    );
  };
  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        // Replace 'https://yourwordpresssite.com/wp-json/wp/v2/recipes' with the actual endpoint for WP Recipe Maker recipes
        const response = await fetch(
          `https://showcase.indiit.com/welfore/wp-json/custom/v1/details/?id=${id}`
        );
        const data = await response.json();
        setVideoData(data);
        setVideoDataLink(data[0].video);
      } catch (error) {
        console.error("Error fetching recipes:", error);
      }
    };

    fetchRecipes();
  }, []);
  const videoString = JSON.stringify(video);
  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="12">
            <div className="border-bottom pb-4 mealplanhead_div">
              <h1>
                <Link to="/Recipes">
                  <ChevronLeft />
                </Link>
                Recipe
              </h1>
              <p>{video.recipeName}</p>
            </div>
          </Col>
        </Row>

        <div class="breadcum mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link>My Library</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/Recipes">Recipes</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Recipe Details</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="card_box mt-5">
          <div className=" recipemain_div">
            <Row className="align-items-center">
              <Col md="12" lg="7" sm="12">
                <h2
                  className="recipetop_name"
                  dangerouslySetInnerHTML={{ __html: video[0]?.title }}
                ></h2>
                {/* <div className="receipeuser d-flex align-items-center my-3">
                  <img className="rounded-circle me-3" src={user1} />{" "}
                  <h3>Brecht</h3>
                </div> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: video[0]?.content,
                  }}
                  className="receipepara mb-3"
                ></div>
                {/* <span className="starrating">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <span className="ms-2">4.23 From 432 Votes</span>
                </span> */}
              </Col>
              <Col md="12" lg="5" sm="12">
                <img
                  className="img-fluid rounded"
                  src={
                    video[0]?.image != false
                      ? video[0]?.image
                      : "https://www.vknow.in/images/thumbs/default-image_600.png"
                  }
                  alt=""
                />
              </Col>
            </Row>

            <ul className="receipe-inline">
              {video[0]?.prep_time != "" ? (
                <li>
                  Prep Time <span>{video[0]?.prep_time}</span>
                </li>
              ) : (
                ""
              )}
              {video[0]?.cook_time != "" ? (
                <li>
                  Cook Time<span>{video[0]?.cook_time}</span>
                </li>
              ) : (
                ""
              )}
              {video[0]?.custom_time != "" ? (
                <li>
                  {video[0]?.custom_time != ""
                    ? video[0]?.custom_level
                    : "Time"}
                  <span>{video[0]?.custom_time}</span>
                </li>
              ) : (
                ""
              )}
              {video[0]?.total_time != "" ? (
                <li>
                  Total Time <span>{video[0]?.total_time}</span>
                </li>
              ) : (
                ""
              )}
            </ul>
            <ul className="receipe-inline">
              {video[0]?.course_name != "" ? (
                <li>
                  Cuisine{" "}
                  <span
                    dangerouslySetInnerHTML={{ __html: video[0]?.course_name }}
                  ></span>
                </li>
              ) : (
                ""
              )}
              {video[0]?.cuisine_name != "" ? (
                <li>
                  Cuisine{" "}
                  <span
                    dangerouslySetInnerHTML={{ __html: video[0]?.cuisine_name }}
                  ></span>
                </li>
              ) : (
                ""
              )}
              {video[0]?.wprm_servings != "" ? (
                <li>
                  Servings <span>{video[0]?.wprm_servings}</span>
                </li>
              ) : (
                ""
              )}
              {video[0]?.calories != "" ? (
                <li>
                  Calories <span>{video[0]?.calories} Kcal</span>
                </li>
              ) : (
                ""
              )}
            </ul>
            {video[0]?.wprm_equipment.length > 0 ? (
              <>
                <h3 class="homemeal_head mt-4">Equipment</h3>
                <RecipeEquipmentList equipment={video[0]?.wprm_equipment} />
                <hr />
              </>
            ) : (
              ""
            )}

            {video[0]?.wprm_ingredients?.length > 0 &&
            video[0]?.wprm_ingredients[0]?.ingredients?.length > 0 ? (
              <>
                <h3 className="homemeal_head mt-4">Ingredients</h3>
                <RecipeEquipmentList
                  equipment={video[0]?.wprm_ingredients[0]?.ingredients}
                />
                <hr />
              </>
            ) : (
              ""
            )}

            {video[0]?.wprm_instructions?.length > 0 &&
            video[0]?.wprm_instructions[0]?.instructions?.length > 0 ? (
              <>
                <h3 class="homemeal_head mt-4">Instructions</h3>
                <Instruction
                  equipment={video[0]?.wprm_instructions[0]?.instructions}
                />
                <hr />
              </>
            ) : (
              ""
            )}

            {videoLink !== "" ? (
              <>
                <h3 className="homemeal_head mt-4">Video</h3>
                <video
                  width="100%"
                  className="rounded object-cover mb-3"
                  muted
                  loop
                  playsInline
                  controls
                >
                  <source src={videoLink} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <hr />
              </>
            ) : (
              ""
            )}

            <h3 class="homemeal_head mt-4">Notes</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: video[0]?.notes,
              }}
              className="receipepara mb-3"
            ></div>
            {/* <p className="receipepara mb-4">{video[0]?.notes}</p> */}
            <hr />
            {/* <h3 class="homemeal_head mt-4">Nutrition</h3>
            <ul className="receipe-inline">
              <li>
                Calories <span>482Kcal</span>
              </li>
              <li>
                Carbohydrates <span>101g</span>
              </li>
              <li>
                Protein <span>14g</span>
              </li>
              <li>
                Fat <span>1g</span>
              </li>
              <li>
                Sodium <span>117mg</span>
              </li>
              <li>
                Potassium <span>230mg</span>
              </li>
              <li>
                Fiber <span>4g</span>
              </li>
              <li>
                Sugar <span>4g</span>
              </li>
              <li>
                Vitamin A <span>520IU</span>
              </li>
              <li>
                Vitamin C <span>32mg</span>
              </li>
              <li>
                Calcium <span>19mg</span>
              </li>
              <li>
                Iron <span>5.8mg</span>
              </li>
            </ul>
            <div className="clearfix d-flex"></div>
            <hr />
            <h3 class="homemeal_head mt-4">Keyword</h3>
            <p className="receipepara mb-3">Vegetarian</p> */}
          </div>
          {/* <Row>
            <Col md="12" lg="12" sm="12" className="colp_10">
              <div className="recipemain_div">
                <h2 className="recipetop_name">{video.recipeName}</h2>
                <ul className="recipemain_top">
                  <li>
                    <Clock size={16} /> {video.time + " min"}
                  </li>
                  <li>
                    <FileText size={16} /> {video.totalServing + " serving"}
                  </li>
                  <li>{video.caloryPerServing + " Kcal/serving"}</li>
                  <li>
                    {video.nutritions &&
                      video.nutritions.map((nutri) => (
                        <span className="me-3">
                          {nutri.value + "g "}
                          <strong>{nutri.name}</strong>
                        </span>
                      ))}
                  </li>
                </ul>
                <div className="recipegradient_box">
                  <div className="recipemainlist_box">
                    <h5>Ingredients</h5>
                    {video.ingredients &&
                      video.ingredients.map((ing) => (
                        <p>
                          <b>{". " + ing.name}</b>
                          {ing.value + "oz"}
                        </p>
                      ))}
                  </div>
                  <div class="recipemainimg_box">
                    <img
                      src={`https://askportia.s3.amazonaws.com/${video.recipeImage}`}
                      alt=""
                    />
                  </div>
                </div>
                <div className="recipemainlist_box recipebg_box">
                  <h5>Instructions</h5>
                  {video.instructions &&
                    video.instructions.map((ins) => <p>{"- " + ins}</p>)}
                </div>
                <div className="recipemainlist_box recipebg_box">
                  <h5>Comments</h5>
                  {video.instructions &&
                    video.instructions.map((comm, i) => (
                      <>
                        {i === 0 && <p>{comm}</p>}
                        {i !== 0 && <p>{"- " + comm}</p>}
                      </>
                    ))}
                </div>
                <div className="recipemainlist_box recipebg_box">
                  <h5>Recipe Description</h5>
                  <p>{video.description}</p>
                </div>
              </div>
            </Col>
          </Row> */}
        </div>
      </div>
      {/* <Pagination className="pagination_div" aria-label="Page navigation">
        <PaginationItem className="nextprev_btn">
          <PaginationLink href="#" previous />
        </PaginationItem>
        <PaginationItem active>
          <PaginationLink href="#">1</PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink href="#">2</PaginationLink>
        </PaginationItem>
        <PaginationItem disabled>
          <PaginationLink href="#">3</PaginationLink>
        </PaginationItem>
        <PaginationItem className="nextprev_btn">
          <PaginationLink href="#" next />
        </PaginationItem>
      </Pagination> */}
    </>
  );
};

export default RecipeDetails;
