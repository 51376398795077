import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import source1 from "../../assets/image/mealplan_icon1.svg";
import source2 from "../../assets/image/mealplan_icon2.svg";
import source3 from "../../assets/image/mealplan_icon3.svg";
import source4 from "../../assets/image/mealplan_icon4.svg";
import source5 from "../../assets/image/mealplan_icon5.svg";
import source6 from "../../assets/image/mealplan_icon6.svg";
import Bariatric from "../../assets/image/img/Bariatric.pdf";
import Diabetes from "../../assets/image/img/Diabetes.pdf";
import HeartHealth from "../../assets/image/img/HeartHealth.pdf";
import VeganMeal from "../../assets/image/img/VeganMeal.pdf";
import VegetarianMeal from "../../assets/image/img/VegetarianMeal.pdf";
import WeightLoss from "../../assets/image/img/WeightLoss.pdf";
import { ChevronLeft } from "react-feather";
import { getData } from "../../api/api";
const Mealplan = (args) => {
  const [mealPlans, setMealPlan] = useState([]);
  const [steticMealPlan, setSteticMealPlan] = useState([]);

  useEffect(() => {
    const fetchtoken = async () => {
      const storedData =
        (await localStorage.getItem("userData")) ||
        sessionStorage.getItem("userData");
      // Parse the JSON string back to an object
      const parsedData = JSON.parse(storedData);

      if (parsedData.user._id) {
        await getData(`mealPlanForUser?userId=${parsedData.user._id}`)
          .then((res) => {
            if (res && res.data && res.data.data) {
              console.log("no");
              setMealPlan(res.data.data);
            } else {
              setMealPlan([]);
              console.log("yes");
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
      }
    };
    const fetchstatic = async () => {
      await getData(`staticMeal`)
        .then((res) => {
          if (res && res.data && res.data.data) {
            console.log(res.data.data);
            setSteticMealPlan(res.data.data);
          } else {
            setSteticMealPlan([]);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    };

    fetchtoken();
    fetchstatic();
  }, []);
  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <div className="mealplanhead_div">
              <h1>
                <Link to="/Home">
                  <ChevronLeft />
                  Meal Plans
                </Link>
              </h1>
              <p>
                Here you will find your Dietitian-Approved personalized meal
                plans with all of your favorite foods and flavors!<br></br> In
                the mean-time and beyond, explore a delicious world of other
                health-focused flavors! Choose from Bariatric, Diabetes, Heart,
                Vegetarian, and Vegan Health plans for a meal plan tailored to
                help achieve and celebrate your wellness goals.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="match-height">
        {steticMealPlan.map((videoItem, index) => (
          <Col lg="3" sm="6" className="colp_10" key={index}>
            <div className="card mealplan_box">
              <Link to={`/StaticMeal/${videoItem.img}/${videoItem.title}`}>
                <div className="card-body">
                  <div className="d-block">
                    <div className="mealplan_img">
                      <img
                        className="borderad-none rounded-0"
                        src={`https://askportia.s3.amazonaws.com/${videoItem.icon}`}
                        height={50}
                        alt={videoItem.title}
                      />
                    </div>
                    <h3>{videoItem.title}</h3>
                    {/* <p className="card-text">
                      {videoItem.des
                        ? `${videoItem.des.substring(0, 50)}...`
                        : "No description available"}
                    </p> */}
                  </div>
                </div>
              </Link>
            </div>
          </Col>
        ))}

        {/* {mealPlans.length > 0 ? (
          mealPlans.map((videoItem, index) => (
            <Col lg="3" sm="6" className="colp_10" key={index}>
              <div className="card mealplan_box">
                <Link to={`/Weightloss/${videoItem._id}`}>
                  <div className="card-body">
                    <div className="d-block">
                      <h3>{videoItem.mealplanName}</h3>
                      <p className="card-text">{videoItem.details}</p>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          ))
        ) : (
          <Col>
            <div className="text-center">No data found</div>
          </Col>
        )} */}
      </Row>
    </>
  );
};

export default Mealplan;
