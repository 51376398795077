import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { UpvoteButton, DownvoteButton } from "./VoteButtons";
import { notify } from "../reducers/notificationReducer";
import EditDeleteMenu from "./EditDeleteMenu";
import getEditedThumbail, {
  getCircularAvatar,
} from "../utils/cloudinaryTransform";
import { trimLink, prettifyLink, fixUrl } from "../utils/formatUrl";
import TimeAgo from "timeago-react";
import getErrorMsg from "../utils/getErrorMsg";

import {
  Paper,
  Typography,
  useMediaQuery,
  CardMedia,
  Link,
  Button,
  Avatar,
} from "@material-ui/core";
import { useCardStyles } from "../styles/muiStyles";
import { useTheme } from "@material-ui/core/styles";
import MessageIcon from "@material-ui/icons/Message";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";

import LinkIcon from "@material-ui/icons/Link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CommentIcon from "@material-ui/icons/Comment";
import { ThumbDown } from "@material-ui/icons";

const PostCard = ({ post, toggleUpvote, toggleDownvote, image }) => {
  const {
    _id: id,
    title,
    postType,
    textSubmission,
    linkSubmission,
    imageSubmission,
    subreddit,
    author,
    upvotedBy,
    downvotedBy,
    pointsCount,
    commentCount,
    createdAt,
    updatedAt,
  } = post;

  const classes = useCardStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [points, setPoints] = useState(0);
  const dispatch = useDispatch();
  // const { user, darkMode } = useSelector((state) => state);
  // console÷.log(user, "user here in post sections ");
  const [user, setUser] = useState(null);
  const [isUpvoted, setisUpvoted] = useState(false);
  const isDownvoted = user && downvotedBy.includes(user.id);
  useEffect(() => {
    // Retrieve user data from sessionStorage
    const storedUserData = sessionStorage.getItem("userData");
    // Parse JSON data if it exists
    setPoints(pointsCount);
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUser(parsedUserData);
      setisUpvoted(upvotedBy.includes(parsedUserData.user._id));
    }
  }, []);
  const handleUpvoteToggle = async () => {
    try {
      if (isUpvoted) {
        let arr = upvotedBy.filter((u) => u !== user.user._id);
        const updatedUpvotedBy = arr;
        setisUpvoted(false);
        setPoints((pts) => pts - 1);
        dispatch(toggleUpvote(id, updatedUpvotedBy, downvotedBy));
      } else {
        let arr = downvotedBy.filter((d) => d !== user.user._id);
        const updatedUpvotedBy = [...upvotedBy, user.id];
        const updatedDownvotedBy = arr;
        setPoints((pts) => pts + 1);
        setisUpvoted(true);
        dispatch(toggleUpvote(id, updatedUpvotedBy, updatedDownvotedBy));
      }
    } catch (err) {
      dispatch(notify(getErrorMsg(err), "error"));
    }
  };

  const handleDownvoteToggle = async () => {
    try {
      if (isDownvoted) {
        const updatedDownvotedBy = downvotedBy.filter((d) => d !== user.id);
        dispatch(toggleDownvote(id, updatedDownvotedBy, upvotedBy));
      } else {
        const updatedDownvotedBy = [...downvotedBy, user.id];
        const updatedUpvotedBy = upvotedBy.filter((u) => u !== user.id);
        dispatch(toggleDownvote(id, updatedDownvotedBy, updatedUpvotedBy));
      }
    } catch (err) {
      dispatch(notify(getErrorMsg(err), "error"));
    }
  };

  // const linkToShow =
  //   postType === "Link"
  //     ? linkSubmission
  //     : postType === "Image"
  //     ? imageSubmission?.imageLink
  //     : "";

  // const formattedLink = trimLink(prettifyLink(linkToShow), 30);

  return (
    <Paper
      className={[classes.root, classes.parentdiv, classes.flexcol]}
      variant=""
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: 10,
          gap: 10,
        }}
      >
        {user && user.user._id === author._id && (
          <Avatar
            src={
              user && user.user && user.user.profilePic
                ? "https://askportia.s3.amazonaws.com/" + user.user.profilePic
                : "https://askportia.s3.amazonaws.com/default-avatar.png" // Assuming you have a default avatar
            }
            alt="User Profile Picture"
          />
        )}

        {user && user.user._id != author._id && (
          <Avatar
            src={
              author && author.profilePic
                ? "https://askportia.s3.amazonaws.com/" + author.profilePic
                : "https://askportia.s3.amazonaws.com/default-avatar.png" // Assuming you have a default avatar
            }
            alt="User Profile Picture"
          />
        )}

        <div style={{}}>
          <strong>{author.firstName ? author.firstName : "N/A"}</strong>
          <br />
          {/* <br /> */}
          <TimeAgo style={{ fontSize: 10 }} datetime={new Date(createdAt)} />
          {/* <p></p> */}
        </div>
      </div>
      {/* <div className={classes.votesWrapper}>
        <UpvoteButton
          user={user}
          body={post}
          handleUpvote={handleUpvoteToggle}
          size={isMobile ? "small" : "medium"}
        />
        <Typography
          variant="body1"
          style={{
            color: isUpvoted
              ? "#FF8b60"
              : isDownvoted
              ? "#9494FF"
              : darkMode
              ? "#e4e4e4"
              : "#333",
            fontWeight: 600,
          }}
        >
          {pointsCount}
        </Typography>
        <DownvoteButton
          user={user}
          body={post}
          handleDownvote={handleDownvoteToggle}
          size={isMobile ? "small" : "medium"}
        />
      </div> */}

      <div className={classes.postInfoWrapper}>
        <Typography variant="h6" className={classes.title}>
          {title}
          {/* <Typography variant="caption" color="primary" className={classes.url}>
            <Link
              href={
                postType === "Link"
                  ? fixUrl(linkSubmission)
                  : postType === "Image"
                  ? imageSubmission?.imageLink
                  : ""
              }
            >
              {formattedLink}
              {postType === "Text" ? null : (
                <OpenInNewIcon fontSize="inherit" />
              )}
            </Link>
          </Typography> */}
        </Typography>
        <div>
          <p style={{ color: "#949494", marginTop: 5, marginBottom: 20 }}>
            {" "}
            {textSubmission ?? <></>}
          </p>
        </div>

        {image && (
          <div className={classes.thumbnailWrapper}>
            {postType === "Text" ? (
              <RouterLink to={`/comments/${id}`}>
                <Paper elevation={0} square className={classes.thumbnail}>
                  <MessageIcon
                    fontSize="inherit"
                    className={classes.thumbnailIcon}
                    style={{ color: "#787878" }}
                  />
                </Paper>
              </RouterLink>
            ) : postType === "Link" ? (
              <a href={fixUrl(linkSubmission)} target="_noblank">
                <Paper elevation={0} square className={classes.thumbnail}>
                  <LinkIcon
                    fontSize="inherit"
                    className={classes.thumbnailIcon}
                    style={{ color: "#787878" }}
                  />
                </Paper>
              </a>
            ) : (
              <Paper elevation={0} square className={classes.thumbnail}>
                <CardMedia
                  className={classes.thumbnail}
                  image={imageSubmission?.imageLink}
                  title={title}
                  component="a"
                  href={imageSubmission?.imageLink}
                  target="_noblank"
                />
              </Paper>
            )}
          </div>
        )}

        <div
          className={classes.bottomBtns}
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          {points}

          <Button
            startIcon={<ThumbUpIcon color={isUpvoted ? "primary" : "ggg"} />}
            className={classes.commentsBtn}
            size={isMobile ? "small" : "medium"}
            // color={isUpvoted ? "blue" : "white"}
            onClick={handleUpvoteToggle}
          ></Button>
          {/* <Button
            startIcon={<ThumbDownIcon />}
            className={classes.commentsBtn}
            size={isMobile ? "small" : "medium"}
            onClick={handleDownvoteToggle}
          ></Button> */}
          <Button
            startIcon={<CommentIcon />}
            className={classes.commentsBtn}
            component={RouterLink}
            to={`/comments/${id}`}
            size={isMobile ? "small" : "medium"}
          >
            {commentCount} comments
          </Button>
          {user && user.user._id === author._id && (
            <>
              <EditDeleteMenu
                id={id}
                isMobile={isMobile}
                title={title}
                postType={postType}
                subreddit={subreddit}
                textSubmission={textSubmission}
                linkSubmission={linkSubmission}
              />
            </>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default PostCard;
