import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.css";
import logo from "../../assets/image/logo.png";
import lock from "../../assets/image/lock.svg";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { ForgotPasswordSchema } from "../../schema";
import { postData } from "../../api/api";
const ForgotPassword = (args) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };
  const { values, handleBlur, handleChange, errors, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values) => {
      await postData(values, "forgotPassword")
        .then((res) => {
          //console.log(res);
          if (res.data.status) {
            alert(
              "Password reset link has been sent to your email. Please check.."
            );
            navigate("/");
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert("Opration Failed from server side");
          navigate("/");
        });
      //
    },
  });
  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col md="5" className="p-0">
              <div className="loginlogo_div">
                <img class="loginlogoimg" src={logo} alt="" />
              </div>
            </Col>
            <Col className="loginfield_bg" md="7">
              <div className="loginmain_div">
                <div className="headlogin_div">
                  <h3 className="login_head">Forgot Your Password</h3>
                  <p className="card-text">
                    Effortlessly manage your lifestyle and discover new ways to
                    stay healthy!
                  </p>
                </div>
                <Form onSubmit={handleSubmit}>
                  <FormGroup className="form-group">
                    <Label className="form-label">Email Address</Label>
                    <Input
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <p className="from-error">{errors.email}</p>
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <Link to="/" className="mt-4 me-2">
                      <Button className="cancel_btn">Cancel</Button>
                    </Link>

                    <Button
                      className="login_btn mt-4"
                      type="submit"
                      color="primary"
                    >
                      Forgot Password
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal} centered toggle={toggle} {...args}>
        <ModalBody className="text-center requestmodal">
          <img class="iconrequest" src={lock} alt="" />
          <h2>Password Reset Request</h2>
          <p>
            We've sent a password reset link to your email. Please check your
            inbox and follow the instructions to reset your password. If you
            don't see the email, kindly check your spam folder
          </p>
          <Link className="mt-4" to="/ResetPassword">
            <Button color="primary" onClick={toggle} className="px-5 mt-2">
              Ok
            </Button>
          </Link>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ForgotPassword;
