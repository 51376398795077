const storageKeyToken = "userData";
const storageKeyDarkMode = "readifyDarkMode";

const saveUser = (user) =>
  sessionStorage.setItem(storageKeyToken, JSON.stringify(user));

const loadUser = () => JSON.parse(sessionStorage.getItem(storageKeyToken));

const logoutUser = () => sessionStorage.removeItem(storageKeyToken);

const saveDarkMode = (boolean) =>
  sessionStorage.setItem(storageKeyDarkMode, boolean);

const loadDarkMode = () => sessionStorage.getItem(storageKeyDarkMode);

const storage = {
  saveUser,
  loadUser,
  logoutUser,
  saveDarkMode,
  loadDarkMode,
};

export default storage;
