import React, { useState } from "react";

const VideoPlayer = ({ videoFile, thumbnail }) => {
  const [playing, setPlaying] = useState(false);
  const [thumbnailVisible, setThumbnailVisible] = useState(true);

  const handleTogglePlay = () => {
    const videoElement = document.querySelector(".video-player video");
    if (playing) {
      videoElement.pause();
    } else {
      videoElement.play();
      setThumbnailVisible(false); // Hide thumbnail when video starts playing
    }
    setPlaying(!playing);
  };

  return (
    <div
      className="video-player"
      onClick={handleTogglePlay}
      style={{ cursor: "pointer" }}
    >
      {thumbnailVisible && (
        <div className="videoplayicon">
          <img
            src={thumbnail}
            alt="Video Thumbnail"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              borderRadius: "15px",
              boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.06)",
            }}
            onClick={handleTogglePlay}
          />
        </div>
      )}

      <video
        controls
        width="auto"
        height="auto"
        onPlay={() => {
          setPlaying(true);
          setThumbnailVisible(false); // Ensure thumbnail is hidden when video plays
        }}
        onPause={() => setPlaying(false)}
        style={thumbnailVisible ? { display: "none" } : {}}
      >
        <source src={`${videoFile}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* <button onClick={handleTogglePlay} className="playvideo_btn">
        {playing ? "Pause" : "Play"}
      </button> */}
    </div>
  );
};

export default VideoPlayer;
