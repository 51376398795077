import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../assets/css/style.css";
import logo from "../../assets/image/logo.png";
import success from "../../assets/image/success.svg";
import eyeshowicon from "../../assets/image/eye-show.svg";
import eyehideicon from "../../assets/image/eye-hide1.svg";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { postData } from "../../api/api";
import { ResetSchema } from "../../schema";
const ResetPassword = (args) => {
  const { email, numericValue } = useParams();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [useremail, setEmail] = useState(email);
  const [uservalue, setnumericValue] = useState(numericValue);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    NewPassword: "",
    RepeatPassword: "",
    Email: useremail,
    Otp: uservalue,
  };
  const { values, handleBlur, handleChange, errors, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ResetSchema,
    onSubmit: async (values) => {
      await postData(
        {
          newPassword: values.NewPassword,
          email: values.Email,
          otp: values.Otp,
        },
        "resetPassword"
      )
        .then((res) => {
          //console.log(res);
          if (res.data.status) {
            alert("Password reset successfully. Please login");
            navigate("/");
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          alert("Opration Failed from server side");
          navigate("/");
        });
      //
    },
  });

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col md="5" className="p-0">
              <div className="loginlogo_div">
                <img class="loginlogoimg" src={logo} alt="" />
              </div>
            </Col>
            <Col className="loginfield_bg" md="7">
              <div className="loginmain_div">
                <div className="headlogin_div">
                  <h3 className="login_head">Reset Your Password</h3>
                  <p className="card-text">
                    Effortlessly manage your lifestyle and discover new ways to
                    stay healthy!
                  </p>
                </div>
                <Form onSubmit={handleSubmit}>
                  <FormGroup className="form-group position-relative">
                    <Input name="Email" type="hidden" value={useremail} />
                    <Input name="Otp" type="hidden" value={uservalue} />
                    <Label className="form-label">New Password</Label>
                    <Input
                      name="NewPassword"
                      placeholder="New Password"
                      type={showPassword1 ? "text" : "password"}
                      value={values.NewPassword}
                      onChange={handleChange}
                    />
                    <p className="from-error">{errors.NewPassword}</p>
                    <div
                      className="iconright"
                      onClick={togglePasswordVisibility1}
                    >
                      {showPassword1 ? (
                        <img src={eyeshowicon} alt="" />
                      ) : (
                        <img src={eyehideicon} alt="" />
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group position-relative">
                    <Label className="form-label">Confirm New Password</Label>
                    <Input
                      name="RepeatPassword"
                      placeholder="Confirm New Password"
                      type={showPassword2 ? "text" : "password"}
                      value={values.RepeatPassword}
                      onChange={handleChange}
                    />
                    <p className="from-error">{errors.RepeatPassword}</p>
                    <div
                      className="iconright"
                      onClick={togglePasswordVisibility2}
                    >
                      {showPassword2 ? (
                        <img src={eyeshowicon} alt="" />
                      ) : (
                        <img src={eyehideicon} alt="" />
                      )}
                    </div>
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <Link to="/ForgotPassword" className="mt-4 me-2">
                      <Button className="cancel_btn">Cancel</Button>
                    </Link>

                    <Button
                      className="mt-4 login_btn"
                      type="submit"
                      color="primary"
                    >
                      Reset Password
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal} centered toggle={toggle} {...args}>
        <ModalBody className="text-center requestmodal">
          <img class="iconrequest" src={success} alt="" />
          <h2>Reset Successfully</h2>
          <p>
            {" "}
            Your password has been successfully reset. You can now use your new
            password to log in to your account. If you have any further
            questions or issues, please contact our support team.
          </p>
          <Link className="mt-4" to="/">
            <Button color="primary" onClick={toggle} className="px-5 mt-2">
              Ok
            </Button>
          </Link>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ResetPassword;
