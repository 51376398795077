import React, { useState, useEffect } from "react";
import { Row, Col, Pagination, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { getData } from "../../api/api";
import { Link } from "react-router-dom";

const Sections = (args) => {
  const [section, setSectionData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData("fecth_section");
        console.log(res.data.data);
        setSectionData(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="12">
            <div className="border-bottom pb-4">
              <h1>Questions Category</h1>
            </div>
          </Col>
        </Row>
        <div class="breadcum mt-3">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link>My Library</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Questionnaire</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="card_box mt-3 p-0 shadow-none rounded-0 bg-transparent">
          <Row>
            {section.map((videoItem, index) => (
              <Col md="6" lg="4" xxl="3" sm="6" className="colp_10" key={index}>
                <Link className="questioncards" to={videoItem.slug}>
                  {videoItem.sectionName}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M22 12a10 10 0 0 1-10 10A10 10 0 0 1 2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10m-2 0a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8a8 8 0 0 0 8-8M8.6 16.6l4.6-4.6l-4.6-4.6L10 6l6 6l-6 6z"
                    />
                  </svg>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Sections;
