import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import check from "../../assets/image/check.svg";
import { Link } from "react-router-dom";
import { getData, postData } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ManageBilling = () => {
  const [token, setToken] = useState(null);
  let [plans, setPlans] = useState([]);
  let [priceid, setPriceId] = useState(null);
  const [cardnumber, setCardId] = useState(false);
  const [cardnumberugrade, setUgradeId] = useState(null);

  let [btnclass, setBtnclass] = useState([
    "dark",
    "blue",
    "green",
    "dark-green",
  ]);

  let [tdclass, setTdclass] = useState([
    "ltgrey",
    "ltblue",
    "ltgreen",
    "ltdgreen",
  ]);

  useEffect(() => {
    const fetchtoken = async () => {
      const storedData =
        (await localStorage.getItem("userData")) ||
        sessionStorage.getItem("userData");
      // Parse the JSON string back to an object
      const parsedData = JSON.parse(storedData);
      if (parsedData.token) {
        setToken(parsedData.token);
      }
    };
    fetchtoken();
  }, []);

  useEffect(() => {
    fetchData();
  }, [token]);
  const fetchData = async () => {
    try {
      const res = await getData(`getProduct`, token);

      setPlans(res.data.data);

      console.log("res.data.totalCount", res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const CancelPlan = async (id) => {
    setCardId(true);
    try {
      const res = await postData(
        {
          payment_method: id,
        },
        "cancelSubscription",
        token
      );
      if (res.data.status) {
        setCardId(false);
        toast(res.data.message);
        window.location.reload();
      } else {
        setCardId(false);
        toast("Validation failed.");
      }
    } catch (error) {
      console.log(error);
      toast("An error occurred while saving the data.");
      setCardId(false);
    }
  };

  const UpgradePlan = async (id) => {
    setUgradeId(id);
    try {
      const res = await postData(
        {
          newPriceId: id,
        },
        "UpdateSubscription",
        token
      );
      if (res.data.status) {
        fetchData();
        setUgradeId(null);
        toast(res.data.message);
        window.location.reload();
      } else {
        setUgradeId(null);
        toast("Validation failed.");
      }
    } catch (error) {
      console.log(error);
      toast("An error occurred while saving the data.");
      setUgradeId(null);
    }
  };

  return (
    <>
      <div className="welcome_Div subsriptionouter ">
        <Row className="align-items-center">
          <Col lg="6">
            <h1>Manage Billing</h1>
          </Col>
        </Row>
        <div class="subsriptionmain w-100 p-3">
          {/* <h2 class="head-subs text-center">B2B2C Subscription Model</h2> */}
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th width="20%"></th>
                  {plans?.map((plan, index) => (
                    <th
                      key={index}
                      width="20%"
                      className={`table${btnclass[index]}`}
                    >
                      {index === plans?.length - 1 ? (
                        <>
                          Enterprise <span>Fee + ${plan.amount}/m</span>
                        </>
                      ) : (
                        `$${plan.amount}/m`
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Group Member #</td>
                  {plans?.map((plan, index) => (
                    <td
                      key={index}
                      className={`td${tdclass[index]} curve${tdclass[index]}`}
                    >
                      {plan.groupMember}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Ethnocentric Meal Plans & Receipes</td>
                  {plans?.map((plan, index) => (
                    <td key={index} className={`td${tdclass[index]}`}>
                      {plan.mealPlanandReceipes && (
                        <img className="loginlogoimg" src={check} alt="check" />
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Hands-On Tools</td>
                  {plans?.map((plan, index) => (
                    <td key={index} className={`td${tdclass[index]}`}>
                      {plan.handsOnTool && (
                        <img className="loginlogoimg" src={check} alt="check" />
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Nutrition</td>
                  {plans?.map((plan, index) => (
                    <td key={index} className={`td${tdclass[index]}`}>
                      {plan.nutrition && (
                        <img className="loginlogoimg" src={check} alt="check" />
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Plans for children</td>
                  {plans?.map((plan, index) => (
                    <td key={index} className={`td${tdclass[index]}`}>
                      {plan.planforchildren && (
                        <img className="loginlogoimg" src={check} alt="check" />
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Community Access</td>
                  {plans?.map((plan, index) => (
                    <td key={index} className={`td${tdclass[index]}`}>
                      {plan.community && (
                        <img className="loginlogoimg" src={check} alt="check" />
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td></td>
                  {plans?.map((plan, index) => (
                    <td key={index}>
                      {plan?.isActive ? (
                        <Link
                          onClick={() => CancelPlan(plan?.cardID)}
                          className={`btn btn-${btnclass[index]}`}
                        >
                          {cardnumber ? "Removing...." : "Cancel"}
                        </Link>
                      ) : (
                        <Link
                          onClick={() => UpgradePlan(plan?.priceId)}
                          className={`btn btn-${btnclass[index]}`}
                        >
                          {cardnumberugrade == plan?.priceId
                            ? "Processing..."
                            : plan?.plan}
                        </Link>
                      )}
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ManageBilling;
