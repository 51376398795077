import React, { useEffect, useState } from "react";
import { Row, Col, Label, Form, FormGroup, Input, Button } from "reactstrap";
import profileimg from "../../assets/image/user1.jpg";
import { Edit2 } from "react-feather";
import { useFormik } from "formik";
import { ProfileSchema } from "../../schema";
import { Link, useNavigate } from "react-router-dom";
import { getData, postData } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Country, State, City } from "country-state-city";

const ProfileSetting = (args) => {
  const navigate = useNavigate();

  const [selectedCountry, setCountry] = useState(null);
  const [selectedState, setState] = useState(null);

  const [codeCountry, setCountryCode] = useState(null);
  const [codeState, setStateCode] = useState(null);

  const [userdata, userData] = useState([]);
  const [token, setToken] = useState(null);
  const [userPic, setuserPic] = useState(
    "http://www.newerahospitalnagpur.com/admin/uploads/donors/4549_ad.png"
  );
  const setProfilePicture = async (params) => {
    const formData = new FormData();
    formData.append("file", params);

    await postData(formData, "updateProfile", token, "formdata")
      .then((res) => {
        if (res.data.status) {
          if (res.data.date.profilePic) {
            console.log("rrr");
            setuserPic(
              "https://askportia.s3.amazonaws.com/" + res.data.date.profilePic
            );
            window.location.reload();
          } else {
            console.log("hhh");
            setuserPic(
              "http://www.newerahospitalnagpur.com/admin/uploads/donors/4549_ad.png"
            );
          }
        } else {
          alert("profile picture updation failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    errors,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "",
      age: "",
      city: "",
      state: "",
      country: "",
      profileimg: null,
      zipCode: "",
    },
    validationSchema: ProfileSchema,
    onSubmit: async (values) => {
      const updatedData = {
        ...values, // Spread the existing data
        countryCode: codeCountry,
        stateCode: codeState,
      };

      await postData(updatedData, "updateProfile", token)
        .then((res) => {
          if (res.data.status) {
            toast("Profile updated successfully");
            setValues({
              firstname: res.data?.data?.firstName || "ee",
              lastname: res.data?.data?.lastName || "ee",
              mobileNumber: res.data.data.mobileNumber,
              email: res.data?.data?.email || "",
              age: res.data?.data?.age || "",
              city: res.data?.data?.city || "",
              state: res.data?.data?.state || "",
              zipCode: res.data?.data?.zipCode || "",
            });
            setCountryCode(res.data?.data?.countryCode);
            setStateCode(res.data?.data?.stateCode);
          }
        })
        .catch((err) => {
          navigate("/ProfileSetting");
        });
    },
  });
  useEffect(() => {
    const fetchtoken = async () => {
      const storedData =
        (await localStorage.getItem("userData")) ||
        sessionStorage.getItem("userData");
      // Parse the JSON string back to an object
      const parsedData = JSON.parse(storedData);

      if (parsedData.token) {
        setToken(parsedData.token);
        await getData("viewUser", parsedData.token)
          .then((res) => {
            if (res.data.status) {
              setValues({
                firstName: res.data?.data?.firstName || "j",
                lastName: res.data?.data?.lastName || "j",
                mobileNumber: res.data.data.mobileNumber,
                email: res.data?.data?.email || "",
                age: res.data?.data?.age || "",
                country: res.data?.data?.country || "",
                city: res.data?.data?.city || "",
                state: res.data?.data?.state || "",
                zipCode: res.data?.data?.zipCode || "",
              });

              if (res.data.data.profilePic) {
                setuserPic(
                  "https://askportia.s3.amazonaws.com/" +
                    res.data.data.profilePic
                );
              } else {
                setuserPic(
                  "http://www.newerahospitalnagpur.com/admin/uploads/donors/4549_ad.png"
                );
              }

              setCountryCode(res.data?.data?.countryCode);
              setStateCode(res.data?.data?.stateCode);
              // setuserPic(res.data?.data?.profilePic);
              //  navigate("/ProfileSetting");
            }
          })
          .catch((err) => {
            navigate("/ProfileSetting");
          });
      } else {
        navigate("/");
      }
    };
    fetchtoken();
  }, []);

  const handleCountryChange = (e) => {
    handleChange(e);

    const selectedIndex = e.target.selectedIndex;
    const selectedText = e.target.options[selectedIndex].id;
    setCountry(selectedText);
    setCountryCode(selectedText);
  };

  const handleState = (e) => {
    handleChange(e); // Call Formik's handleChange to update form state
    const selectedIndex = e.target.selectedIndex;
    const selectedText = e.target.options[selectedIndex].id;
    setState(selectedText);
    setStateCode(selectedText);
  };

  return (
    <>
      <div className="welcome_Div">
        <Row>
          <Col lg="8">
            <div className="border-bottom pb-4">
              <h1>Profile</h1>
              <p>Amend your profile settings</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="card_box">
        <Row>
          <Col md="12">
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="12" lg="12">
                  <div className="user-avatar-section">
                    <img className="useravatarimg" src={userPic} alt="" />
                    <label for="change-img" className="uploadphoto_btn">
                      Upload Photo
                      <input
                        type="file"
                        id="change-img"
                        accept="image/*"
                        onChange={(event) => {
                          setProfilePicture(event.currentTarget.files[0]);
                        }}
                      />
                    </label>
                  </div>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">First Name</label>
                    <Input
                      name="firstName"
                      placeholder="First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      type="text"
                    />
                  </FormGroup>
                  <p className="from-error">{errors.firstName}</p>
                </Col>
                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">Last Name</label>
                    <Input
                      name="lastName"
                      placeholder="Last Name"
                      value={values.lastName}
                      onChange={handleChange}
                      type="text"
                    />
                  </FormGroup>
                  <p className="from-error">{errors.lastName}</p>
                </Col>
                <Col md="12" lg="12">
                  <FormGroup className="form-group">
                    <label className="form-label">Email Address</label>
                    <Input
                      name="email"
                      placeholder="Email Address"
                      value={values.email}
                      onChange={handleChange}
                      type="email"
                    />
                  </FormGroup>
                  <p className="from-error">{errors.email}</p>
                </Col>
                <Col md="12" lg="12">
                  <FormGroup className="form-group">
                    <Label className="form-label">Age</Label>
                    <Input
                      name="age"
                      placeholder="Age"
                      value={values.age}
                      type="text"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <p className="from-error">{errors.age}</p>
                </Col>
                <Col md="12" lg="12">
                  <FormGroup className="form-group">
                    <label className="form-label">Zipcode</label>
                    <Input
                      name="zipCode"
                      placeholder="Zipcode"
                      value={values.zipCode}
                      onChange={handleChange}
                      type="tel"
                    />
                  </FormGroup>
                  <p className="from-error">{errors.Zipcode}</p>
                </Col>

                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label">Country</Label>
                    <Input
                      type="select"
                      name="country"
                      value={values.country}
                      onChange={handleCountryChange}
                    >
                      <option value="">Select Country</option>

                      {Country.getAllCountries().map((element, index) => (
                        <option
                          key={index}
                          value={element.name}
                          id={element.isoCode}
                        >
                          {element.name}
                        </option>
                      ))}
                    </Input>
                    {errors.zipCode && touched.zipCode ? (
                      <span className="from-error">{errors.country}</span>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col md="12" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label">State</Label>
                    <Input
                      type="select"
                      name="state"
                      value={values.state}
                      onChange={handleState}
                    >
                      <option value="">Select State</option>
                      {State.getStatesOfCountry(codeCountry).map(
                        (element, index) => (
                          <option
                            key={index}
                            value={element.name}
                            id={element.isoCode}
                          >
                            {element.name}
                          </option>
                        )
                      )}
                    </Input>
                    {errors.state && touched.state ? (
                      <span className="from-error">{errors.state}</span>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6" lg="6">
                  <FormGroup className="form-group">
                    <Label className="form-label">City</Label>
                    <Input
                      type="select"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                    >
                      <option value="">Select City</option>
                      {City.getCitiesOfState(codeCountry, codeState).map(
                        (element, index) => (
                          <option
                            key={index}
                            value={element.name}
                            id={element.name}
                          >
                            {element.name}
                          </option>
                        )
                      )}
                    </Input>
                    {errors.city && touched.city ? (
                      <span className="from-error">{errors.city}</span>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col md="12" lg="12">
                  <FormGroup className="form-group">
                    <label className="form-label">Phone No.</label>
                    <Input
                      name="mobileNumber"
                      placeholder="Phone No"
                      value={values.mobileNumber}
                      onChange={handleChange}
                      type="tel"
                    />
                  </FormGroup>
                  <p className="from-error">{errors.mobileNumber}</p>
                </Col>
              </Row>
              <div className="d-flex">
                <Button className="cancel_btn mt-4 me-2">Cancel</Button>
                <Button
                  className="login_btn mt-4"
                  type="submit"
                  color="primary"
                >
                  Save
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </>
  );
};

export default ProfileSetting;
