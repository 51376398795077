import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postData } from "../api/api";
import { Navigate } from "react-router-dom";

// Load your publishable key from Stripe
const stripePromise = loadStripe(
  "pk_test_51NSDu1E4fx6kw2SlG5L4L7FnQXsjt9tkQ5CLkYqBKB7kIqKRFn4FvTW0Sxkk0rLCFnWypXrs9TGD7miUQu6Nq9C300cCcyzQjt"
);

const CheckoutForm = ({ token, toggle }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [modal, setModal] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message);
    } else {
      // Handle successful payment method creation here
      setPaymentSuccessful(true);
      console.log(paymentMethod);

      console.log("checkout" + token);
      // Send paymentMethod.id to your server for processing
      try {
        const res = await postData(
          {
            payment_method: paymentMethod.id,
          },
          "saveCard",
          token
        );                                                                          
        if (res.data && res.data.status) {
          toast(res.data.message);
          toggle();
        } else {
          toast(res);
        }
      } catch (error) {
        console.log(error);
        toast("An error occurred while saving the data.");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="addcardform">
      <div className="text-center">
        <h2 className="card_head">Add Card</h2>
        <p class="card-text">
          Please fill the card details below to proceed further.
        </p>
        <CardElement className="stripepaymentfield" />
        <button
          type="submit"
          disabled={!stripe}
          className="btn btn-primary min-width-140 mt-3"
        >
          Add
        </button>
      </div>
      {error && <div role="alert">{error}</div>}
      {paymentSuccessful && <div>Card added successfully</div>}
    </form>
  );
};

const AddCardPayment = ({ token, toggle }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm token={token} toggle={toggle} />
      <ToastContainer />
    </Elements>
  );
};

export default AddCardPayment;
