import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const CustomPagination = ({ currentPage, totalItems, pageSize, paginate }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / pageSize); i++) {
    pageNumbers.push(i);
  }

  const lastPage = pageNumbers.length;
  const next = currentPage + 1 > lastPage ? currentPage : currentPage + 1;

  // Only show first 3, current (with one before and after), and last page
  const visiblePages = pageNumbers.filter((pageNumber) => {
    return (
      pageNumber === 1 ||
      pageNumber === 2 ||
      pageNumber === 3 ||
      pageNumber === lastPage ||
      (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
    );
  });

  return (
    <Pagination>
      {visiblePages.map((number, index, array) => (
        <>
          {index > 0 && visiblePages[index - 1] !== number - 1 && (
            <PaginationItem disabled>
              <PaginationLink>...</PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem active={number === currentPage} key={number}>
            <PaginationLink onClick={() => paginate(number)}>
              {number}
            </PaginationLink>
          </PaginationItem>
        </>
      ))}
      {currentPage < lastPage && (
        <>
          {lastPage - currentPage > 1 && (
            <PaginationItem disabled>
              <PaginationLink>...</PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem>
            <PaginationLink onClick={() => paginate(next)}>Next</PaginationLink>
          </PaginationItem>
        </>
      )}
    </Pagination>
  );
};

export default CustomPagination;
