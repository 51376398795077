import React, { useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Label,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import classnames from "classnames";
import { getData, postData } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [allNotifications, setAllQuestion] = useState([]);
  const [token, setToken] = useState(null);
  const [QuestionGroup, setQuestionGroup] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [next, setNext] = useState(true);
  const [pre, setPrevious] = useState(false);
  const [showsubmit, setShowsubmit] = useState(false);
  const [requiredFields, setRequiredFileds] = useState([]);
  const [open, setOpen] = useState("1");

  const [Section, setSections] = useState([]);

  const toggleNext = () => {
    setActiveTab(activeTab + 1);
  };

  const togglePre = () => {
    setActiveTab(activeTab - 1);
  };

  useEffect(() => {
    const numberOfKeys = Object.keys(QuestionGroup).length;

    if (activeTab >= 0) {
      setNext(true);
      // setShowsubmit(false);
    }

    if (activeTab == numberOfKeys - 1) {
      setNext(false);
      setShowsubmit(true);
    }

    if (activeTab == 0) {
      setPrevious(false);
      // setShowsubmit(false);
    }

    if (activeTab == numberOfKeys - 1 || activeTab > 0) {
      setPrevious(true);
      // setShowsubmit(false);
    }

    if (activeTab < numberOfKeys - 1) {
      // setPrevious(true);
      setShowsubmit(false);
    }
  }, [activeTab]);

  const getQuestions = async (id) => {
    try {
      const res = await getData(`fetchQuestionOfSection?sectionId=${id}`);
      await setAllQuestion(res.data.data);
      console.log(allNotifications);
    } catch (e) {}
  };

  const onCheckboxChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (!checkboxes[name]?.includes(value)) {
      setCheckboxes({
        ...checkboxes,
        [name]: [...(checkboxes[name] || []), value],
      });
    } else {
      // remove from list
      setCheckboxes({
        ...checkboxes,
        [name]: [...(checkboxes[name] || [])].filter((i) => i !== value),
      });
    }
  };

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
    getQuestions(id);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData("fecth_section");
        setSections(res.data.data);
        // const requiredIds = res.data.data
        //   .filter((question) => question.isRequired)
        //   .map((question) => question._id);
        // setRequiredFileds(requiredIds);
        // setAllQuestion(res.data.data);
        // const storedData =
        //   localStorage.getItem("userData") ||
        //   sessionStorage.getItem("userData");
        // const storedDataparse = JSON.parse(storedData);
        // console.log(token);
        // // Access the token property
        // setToken(storedDataparse.token);

        // const questionGroups = {};

        // res.data.data.forEach((question) => {
        //   const typeId = question.questionTypeId;

        //   if (!questionGroups[typeId]) {
        //     questionGroups[typeId] = [];
        //   }

        //   questionGroups[typeId].push(question);
        // });

        // setQuestionGroup(questionGroups);
        // console.log(questionGroups);
      } catch (error) {
        console.log(error);
      }
    };
    // Call the fetch token function
    fetchData();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    const form = document.getElementById("questions_form");
    const data = Object.fromEntries(new FormData(form).entries());

    const finalObj = {
      ...data,
      ...checkboxes,
    };

    const dataArray = Object.keys(finalObj).map((key) => {
      return {
        questionId: key,
        answer: finalObj[key],
      };
    });

    const missingOrEmptyResponses = requiredFields.filter((requiredId) => {
      // Check if there is no response with the required questionId or the response is an empty string
      return !dataArray.some(
        (response) =>
          response.questionId === requiredId && response.answer !== ""
      );
    });

    console.log(dataArray);
    console.log(requiredFields);
    console.log(missingOrEmptyResponses);
    if (missingOrEmptyResponses.length > 0) {
      toast.error("Please fill all the required questions.");
    } else {
      await postData(
        {
          userAnswer: dataArray,
        },
        "insertUserAnswer",
        token
      )
        .then((res) => {
          res.data.status
            ? toast.success(res.data.message)
            : toast.error("somthing went wrong");
        })
        .catch((err) => {
          toast(err);
          //navigate("/Signup");
        });
    }
  };

  return (
    <>
      {Section.map((data, index) => (
        <Accordion open={open} toggle={() => toggle(data._id)}>
          <AccordionItem>
            <AccordionHeader targetId={data._id}>
              {data.sectionName}
            </AccordionHeader>
            <AccordionBody accordionId={data._id}>
              <form
                onSubmit={onSubmit}
                id="questions_form"
                className="questionaretabs"
              >
                <div>
                  <Nav tabs>
                    {allNotifications.map((uniqueQuestionTypeId, index) => (
                      <NavItem key={index}>
                        <NavLink
                          className={classnames({
                            active: activeTab === index,
                          })}
                        >
                          {uniqueQuestionTypeId.subsectionName}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>

                  <TabContent className="mb-3 mt-3" activeTab={activeTab}>
                    {allNotifications.map((uniqueQuestionTypeId, index) => (
                      <TabPane key={index} tabId={index}>
                        <Row>
                          <Col sm="12">
                            {uniqueQuestionTypeId.questions.map(
                              (question, questionIndex) => (
                                <div className="mb-3" key={questionIndex}>
                                  <h4 className="questionheading">
                                    {question.question}{" "}
                                    {question.isRequired && (
                                      <sup style={{ color: "red" }}>*</sup>
                                    )}
                                  </h4>
                                  {question.inputType === "select" && (
                                    <select
                                      className="form-control"
                                      name={question._id}
                                    >
                                      <option value="">Select Option</option>
                                      {question.answer.map(
                                        (answer, answerIndex) => (
                                          <option
                                            value={answer}
                                            key={answerIndex}
                                          >
                                            {answer}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  )}

                                  {question.inputType === "text" && (
                                    <input
                                      className="form-control"
                                      type="text"
                                      name={question._id}
                                    />
                                  )}

                                  {question.inputType === "textarea" && (
                                    <textarea
                                      className="form-control"
                                      rows="4"
                                      name={question._id}
                                    ></textarea>
                                  )}

                                  {question.inputType === "checkbox" && (
                                    <>
                                      {question.answer.map(
                                        (protein, proteinIndex) => (
                                          <React.Fragment key={proteinIndex}>
                                            <div className="questionform_check form-check">
                                              <Input
                                                checked={(
                                                  checkboxes[question._id] || []
                                                ).includes(protein)}
                                                onChange={(e) => {
                                                  onCheckboxChange(e);
                                                }}
                                                type="checkbox"
                                                value={protein}
                                                name={question._id}
                                              />
                                              <Label className="form-check-label">
                                                {protein}
                                              </Label>
                                            </div>
                                          </React.Fragment>
                                        )
                                      )}
                                    </>
                                  )}
                                </div>
                              )
                            )}
                          </Col>
                        </Row>
                      </TabPane>
                    ))}
                  </TabContent>
                </div>
              </form>
              <ToastContainer />
              {pre && (
                <button
                  className="cancel_btn btn btn-secondary me-2 mb-2"
                  onClick={togglePre}
                >
                  Previous
                </button>
              )}
              {next && (
                <button
                  className="login_btn btn btn-primary mb-2"
                  onClick={toggleNext}
                >
                  Next
                </button>
              )}

              {showsubmit && (
                <button
                  className="mb-2 login_btn btn btn-primary"
                  type="submit"
                  onClick={onSubmit}
                >
                  Submit
                </button>
              )}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      ))}
    </>
  );
};

export default Tabs;
