import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../assets/css/style.css";
import logo from "../../assets/image/logo.png";
import success from "../../assets/image/success.svg";
import eyeshowicon from "../../assets/image/eye-show.svg";
import eyehideicon from "../../assets/image/eye-hide1.svg";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { postData } from "../../api/api";
import { ChangePasswordSchema, ResetSchema } from "../../schema";
const ChangePassword = (args) => {
  const { email, numericValue } = useParams();
  const [modal, setModal] = useState(false);
  const [token, setToken] = useState(null);

  const toggle = () => setModal(!modal);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [useremail, setEmail] = useState(email);
  const [uservalue, setnumericValue] = useState(numericValue);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const togglePasswordVisibility3 = () => {
    setShowPassword3(!showPassword3);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchtoken = async () => {
      const storedData =
        (await localStorage.getItem("userData")) ||
        sessionStorage.getItem("userData");
      // Parse the JSON string back to an object
      const parsedData = JSON.parse(storedData);
      if (parsedData.token) {
        setToken(parsedData.token);
      }
    };
    fetchtoken();
  }, []);

  const initialValues = {
    NewPassword: "",
    RepeatPassword: "",
    CurrentPassword: "",
  };
  const { values, handleBlur, handleChange, errors, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values) => {
      console.log(token);

      await postData(
        {
          newPassword: values.NewPassword,
          currentPassword: values.CurrentPassword,
        },
        "userChangePassword",
        token
      )
        .then((res) => {
          //console.log(res);
          if (res.data.status) {
            toast(res.data.message);
          } else {
            toast(res.data.message);
          }
        })
        .catch((err) => {
          alert(`Opration Failed from server side ${err}`);
        });
    },
  });

  return (
    <>
      <div className="card_box">
          <Row>
            <Col md="12">
                <Form onSubmit={handleSubmit}>
                  <FormGroup className="form-group position-relative">
                    <Label className="form-label">Current Password</Label>
                    <Input
                      name="CurrentPassword"
                      placeholder="Current Password"
                      type={showPassword1 ? "text" : "password"}
                      value={values.CurrentPassword}
                      onChange={handleChange}
                    />
                    <p className="from-error">{errors.CurrentPassword}</p>
                    <div
                      className="iconright"
                      onClick={togglePasswordVisibility1}
                    >
                      {showPassword1 ? (
                        <img src={eyeshowicon} alt="" />
                      ) : (
                        <img src={eyehideicon} alt="" />
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group position-relative">
                    <Label className="form-label">New Password</Label>
                    <Input
                      name="NewPassword"
                      placeholder="New Password"
                      type={showPassword2 ? "text" : "password"}
                      value={values.NewPassword}
                      onChange={handleChange}
                    />
                    <p className="from-error">{errors.NewPassword}</p>
                    <div
                      className="iconright"
                      onClick={togglePasswordVisibility2}
                    >
                      {showPassword2 ? (
                        <img src={eyeshowicon} alt="" />
                      ) : (
                        <img src={eyehideicon} alt="" />
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group position-relative">
                    <Label className="form-label">Confirm New Password</Label>
                    <Input
                      name="RepeatPassword"
                      placeholder="Confirm New Password"
                      type={showPassword3 ? "text" : "password"}
                      value={values.RepeatPassword}
                      onChange={handleChange}
                    />
                    <p className="from-error">{errors.RepeatPassword}</p>
                    <div
                      className="iconright"
                      onClick={togglePasswordVisibility3}
                    >
                      {showPassword3 ? (
                        <img src={eyeshowicon} alt="" />
                      ) : (
                        <img src={eyehideicon} alt="" />
                      )}
                    </div>
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="mt-4 login_btn"
                      type="submit"
                      color="primary"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
            </Col>
          </Row>
      </div>
      <Modal isOpen={modal} centered toggle={toggle} {...args}>
        <ModalBody className="text-center requestmodal">
          <img class="iconrequest" src={success} alt="" />
          <h2>Reset Successfully</h2>
          <p>
            {" "}
            Your password has been successfully reset. You can now use your new
            password to log in to your account. If you have any further
            questions or issues, please contact our support team.
          </p>
          <Link className="mt-4" to="/">
            <Button color="primary" onClick={toggle} className="px-5 mt-2">
              Ok
            </Button>
          </Link>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ChangePassword;
